import React, { Component } from 'react'
import ApplicationBlocker from "../../reusables/loaders/ApplicationBlocker";
import { postRequest } from "../../services/HttpService";
import uniquid from "uniqid";
import { DoNotRefresh, PleaseWait, ProcessFailed, UnexpectedError } from '../../config/CommonConstants';
import { getUTCDate } from '../../utils/DateUtilities';
import { generateExcelSheet } from '../../utils/GenerateExcel';

export default class ReportsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            loadingDescription: "",
            loadingTitle: "",
            showCloseButton: false,
            showLoadingSpinner: false,
            extraInfo: "",
            opacity: 1,
            reload: false,
        }
    }

    downloadSubscriptionEndingTodayReport = async (e) => {
        try {

            this.beingQueried();

            let { code, message, result: { records } } = await postRequest("/web/admin/query", { payload: { currentDate: getUTCDate() }, options: { uri: "/set-report" } }, uniquid());
            if (code > 300)
                throw new Error(message);

            this.sheetWait();

            let array = [];
            records.forEach((record) => {
                let { customer, package: pack } = record;
                array.push([
                    customer.name,
                    customer.customerId,
                    customer.mobileNumber,
                    customer.email,
                    new Date(record.startDate).toDateString(),
                    new Date(record.endDate).toDateString(),
                    record.feePaid,
                    pack.price,
                    pack.name
                ]);
            });
            await generateExcelSheet({
                fileName: "Subscription Ending Today Report.xlsx",
                sheetName: "SET Sheet",
                columns: "SETReportColumns",
                rows: array
            }
            );
            this.setState({
                opacity: 1,
                showLoading: false
            });
        } catch (error) {
            return this.setState({
                showLoading: true, loadingTitle: ProcessFailed,
                loadingDescription: error.message,
                extraInfo: UnexpectedError,
                showLoadingSpinner: false,
                showCloseButton: true,
                opacity: 0.3
            });
        }
    }

    subscriptionAmountPendingReport = async (e) => {
        try {

            this.beingQueried();

            let { code, message, result: { records } } = await postRequest("/web/admin/query", { payload: { currentDate: getUTCDate() }, options: { uri: "/sap-report" } }, uniquid());
            if (code > 300)
                throw new Error(message);

            this.sheetWait();

            let array = [];
            records.forEach((record) => {
                let { customer, package: pack } = record;
                array.push([
                    customer.name,
                    customer.customerId,
                    customer.mobileNumber,
                    customer.email,
                    new Date(record.startDate).toDateString(),
                    new Date(record.endDate).toDateString(),
                    record.feePaid,
                    pack.price,
                    pack.name
                ]);
            });

            await generateExcelSheet({
                fileName: "Subscription Amount Pending Report.xlsx",
                sheetName: "SAP Sheet",
                columns: "SETReportColumns",
                rows: array
            }
            );
            this.setState({
                opacity: 1,
                showLoading: false
            });
        } catch (error) {
            return this.setState({
                showLoading: true, loadingTitle: ProcessFailed,
                loadingDescription: error.message,
                extraInfo: UnexpectedError,
                showLoadingSpinner: false,
                showCloseButton: true,
                opacity: 0.3
            });
        }
    }

    exportAllCustomers = async (e) => {
        try {

            this.beingQueried();

            let { code, message, result: { records } } = await postRequest("/web/admin/query", { payload: { currentDate: getUTCDate() }, options: { uri: "/export-customers" } }, uniquid());

            if (code > 300)
                throw new Error(message);

            this.sheetWait();

            let array = [];
            records.forEach((customer) => {
                array.push([
                    customer.name,
                    customer.customerId,
                    customer.mobileNumber,
                    customer.email,
                    new Date(customer.date).toDateString(),
                    customer.address
                ]);
            });
            await generateExcelSheet({
                fileName: "Customers.xlsx",
                sheetName: "Customers",
                columns: "ExportCustomersColumns",
                rows: array
            }
            );
            this.setState({
                opacity: 1,
                showLoading: false
            });
        } catch (error) {
            return this.setState({
                showLoading: true, loadingTitle: ProcessFailed,
                loadingDescription: error.message,
                extraInfo: UnexpectedError,
                showLoadingSpinner: false,
                showCloseButton: true,
                opacity: 0.3
            });
        }
    }

    exportAllPackages = async (e) => {
        try {

            this.beingQueried();

            let { code, message, result: { records } } = await postRequest("/web/admin/query", { payload: {}, options: { uri: "/export-packages" } }, uniquid());

            if (code > 300)
                throw new Error(message);

            this.sheetWait();

            let array = [];
            records.forEach((record) => {
                array.push([
                    record.name,
                    record.duration,
                    record.price
                ]);
            });
            await generateExcelSheet({
                fileName: "Packages.xlsx",
                sheetName: "Packages",
                columns: "ExportPackagesColumns",
                rows: array
            }
            );
            this.setState({
                opacity: 1,
                showLoading: false
            });
        } catch (error) {
            return this.setState({
                showLoading: true, loadingTitle: ProcessFailed,
                loadingDescription: error.message,
                extraInfo: UnexpectedError,
                showLoadingSpinner: false,
                showCloseButton: true,
                opacity: 0.3
            });
        }
    }

    exportAllDiscounts = async (e) => {
        try {

            this.beingQueried();

            let { code, message, result: { records } } = await postRequest("/web/admin/query", { payload: { token: this.props.token }, options: { uri: "/export-discounts" } }, uniquid());

            if (code > 300)
                throw new Error(message);

            this.sheetWait();

            let array = [];
            records.forEach((record) => {
                array.push([
                    record.name,
                    record.price
                ]);
            });
            await generateExcelSheet({
                fileName: "Discounts.xlsx",
                sheetName: "Discounts",
                columns: "ExportDiscountsColumns",
                rows: array
            }
            );
            this.setState({
                opacity: 1,
                showLoading: false
            });
        } catch (error) {
            return this.setState({
                showLoading: true, loadingTitle: ProcessFailed,
                loadingDescription: error.message,
                extraInfo: UnexpectedError,
                showLoadingSpinner: false,
                showCloseButton: true,
                opacity: 0.3
            });
        }
    }

    exportAllPayments = async (e) => {
        try {

            this.beingQueried();

            let { code, message, result: { records } } = await postRequest("/web/admin/query", { payload: { token: this.props.token }, options: { uri: "/export-payments" } }, uniquid());
            if (code > 300)
                throw new Error(message);

            this.sheetWait();

            let array = [];
            records.forEach((record) => {
                let { customer, subscription: { package: pack, discount = undefined, startDate, endDate } } = record;
                array.push([
                    customer.name,
                    customer.mobileNumber,
                    new Date(record.date).toDateString(),
                    pack.name,
                    pack.price,
                    discount ? discount.name : "NA",
                    discount ? discount.price : "NA",
                    record.price,
                    new Date(startDate).toDateString(),
                    new Date(endDate).toDateString()
                ]);
            });
            await generateExcelSheet({
                fileName: "Payments.xlsx",
                sheetName: "Payments",
                columns: "ExportPaymentsColumns",
                rows: array
            }
            );
            this.setState({
                opacity: 1,
                showLoading: false
            });
        } catch (error) {
            return this.setState({
                showLoading: true, loadingTitle: ProcessFailed,
                loadingDescription: error.message,
                extraInfo: UnexpectedError,
                showLoadingSpinner: false,
                showCloseButton: true,
                opacity: 0.3
            });
        }
    }



    unsetBusyState = async () => {
        if (this.state.reload)
            return this.props.history.goBack();
        return this.setState({ showLoading: false, opacity: 1, redirect: "" });
    };

    sheetWait = () => {

        this.setState({
            loadingTitle: PleaseWait,
            loadingDescription: "Generating Excel Sheet, It May Take A While !",
            showLoading: true,
            showLoadingSpinner: true,
            showCloseButton: false,
            extraInfo: DoNotRefresh,
            opacity: 0.3
        });
    }

    beingQueried = () => {
        this.setState({
            loadingTitle: PleaseWait,
            loadingDescription: "Fetching Data For Requested Excel Reports, It May Take A While !",
            showLoading: true,
            showLoadingSpinner: true,
            showCloseButton: false,
            extraInfo: DoNotRefresh,
            opacity: 0.3
        });
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ opacity: this.state.opacity }}>
                    <div className="jumbotron jumbotron-fluid mt-0 mb-0 pb-0 pt-3 bg-white">
                        <div className="container-fluid mb-5">
                            <div className="row">
                                <div className="col-sm-4 text-center py-2">
                                    <button className="btn btn-primary btn-sm me-md-2 mr-5 w-100" type="button"
                                        onClick={this.exportAllCustomers}>
                                        <i className="fa fa-download mr-2" aria-hidden="true"></i>Export Customers
                                    </button>
                                </div>

                                <div className="col-sm-4 text-center py-2">
                                    <button className="btn btn-primary btn-sm me-md-2 mr-5 w-100" type="button"
                                        title="Subscription Amount Pending Report"
                                        onClick={this.subscriptionAmountPendingReport}>
                                        <i className="fa fa-download mr-2" aria-hidden="true"></i>S.A.P Report
                                    </button>
                                </div>

                                <div className="col-sm-4 text-center py-2">
                                    <button className="btn btn-primary btn-sm me-md-2 w-100" type="button"
                                        title="Subscription Ending Today Report"
                                        onClick={this.downloadSubscriptionEndingTodayReport}>
                                        <i className="fa fa-download mr-2" aria-hidden="true"></i>S.E.T Report
                                    </button>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-sm-4 text-center py-2">
                                    <button className="btn btn-primary btn-sm me-md-2 mr-5 w-100" type="button"
                                        onClick={this.exportAllPackages}>
                                        <i className="fa fa-download mr-2" aria-hidden="true"></i>Export Packages
                                    </button>
                                </div>

                                <div className="col-sm-4 text-center py-2">
                                    <button className="btn btn-primary btn-sm me-md-2 mr-5 w-100" type="button"
                                        title="Subscription Amount Pending Report"
                                        onClick={this.exportAllDiscounts}>
                                        <i className="fa fa-download mr-2" aria-hidden="true"></i>Export Discounts
                                    </button>
                                </div>

                                <div className="col-sm-4 text-center py-2">
                                    <button className="btn btn-primary btn-sm me-md-2 w-100" type="button"
                                        title="Subscription Ending Today Report"
                                        onClick={this.exportAllPayments}>
                                        <i className="fa fa-download mr-2" aria-hidden="true"></i>Export Payments
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showLoading && (
                    <ApplicationBlocker
                        title={this.state.loadingTitle}
                        showCloseButton={this.state.showCloseButton}
                        unsetBusyState={this.unsetBusyState}
                        description={this.state.loadingDescription}
                        extraInfo={this.state.extraInfo}
                        showLoadingSpinner={this.state.showLoadingSpinner}
                    />
                )}
            </React.Fragment>
        )
    }
}
