import React, { Component } from 'react';
import Resizer from 'react-image-file-resizer';
import ApplicationBlocker from "../../reusables/loaders/ApplicationBlocker";
import { postRequest } from "../../services/HttpService";
import uniquid from "uniqid";
import { Uploading, UploadingImage } from '../../config/AddCustomerConstants';
import { CheckingInputs, DoNotRefresh, PleaseWait, ProcessFailed, ServerError, Success, UnexpectedError } from '../../config/CommonConstants';
import GoBackComponent from '../../reusables/utils/GoBackComponent';
import TextInputComponent from '../../reusables/inputs/TextInputComponent';
import LableComponent from '../../reusables/info/LableComponent';
import SmallComponent from '../../reusables/info/SmallComponent';
import NumberInputComponent from "../../reusables/inputs/NumberInputComponent";
import TextareaInputComponent from '../../reusables/inputs/TextareaInputComponent';
import DateInputComponent from '../../reusables/inputs/DateInputComponent';
import SelectInputComponent from '../../reusables/inputs/SelectInputComponent';
import SwitchInputComponent from "../../reusables/inputs/SwitchInputComponent";
import { getUTCDate } from '../../utils/DateUtilities';
const ClientConfig = require('../../config/ClientConfig').DEVELOPMENT;
class AddCustomerForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customer: {
                image: undefined,
                name: "",
                mobileNumber: "",
                date: undefined,
                email: "",
                address: "",
                customerId: "",
                subscriptions: [],
                package: undefined,
                discount: undefined,
                isFeePaid: true
            },
            customerImageUrl: undefined,
            showLoading: false,
            loadingDescription: "",
            loadingTitle: "",
            showCloseButton: false,
            loginFormOpacity: 1,
            showLoadingSpinner: false,
            extraInfo: "",
            opacity: 1,
            reload: false,
            packages: [],
            discounts: []
        }
    }

    unsetBusyState = async () => {
        if (this.state.reload)
            return window.location.reload();
        return this.setState({ showLoading: false, opacity: 1, redirect: "" });
    };

    checkAuth = () => {
        if (!this.props.location.auth)
            return this.props.history.replace('/home/dashboard');
    }

    resizeFile = (file, ext) => new Promise(resolve => {
        Resizer.imageFileResizer(file, 1280, 720, ext, 100, 0,
            uri => {
                resolve(uri);
            },
            'base64',
            500,
            500
        );
    });

    uploadImage = async (e) => {
        try {
            e.preventDefault();
            this.setState({ showCloseButton: false, showLoadingSpinner: true, showLoading: true, loadingTitle: Uploading, loadingDescription: UploadingImage, extraInfo: DoNotRefresh, opacity: 0.3 });
            let file = e.target.files[0];
            if (!file)
                throw new Error("You Was Supposed To Select A File !");
            if (((file.size / 1024) / 1024) > 12) {
                throw new Error(`Your Selected File Is Of ${(Math.round((file.size / 1024) / 1024))} MB. Any File Must Not Be Greater Then 12 MB !`);
            }
            let ext = file.name.split('.').pop().toLowerCase();
            if (ext !== 'png' && ext !== 'jpg' && ext !== 'jpeg')
                throw new Error(`Your Selected File Is A ${ext} File. Only png, jpeg, jpg File(s) Can Uploaded !`);
            let image = { fileName: file.name, type: file.type, extension: file.name.split('.')[1] };
            image.name = uniquid() + "PGH" + uniquid() + "." + image.extension;
            let base64 = await this.resizeFile(file, ext);
            image.content = base64.split(',')[1];
            let request = { payload: { image: image, token: this.props.token }, options: { uri: "/upload-image" } };
            let { data, code, message } = await postRequest('/admin/upload', request, uniquid());
            if (code === 417)
                return this.setState({ showCloseButton: true, showLoadingSpinner: false, showLoading: true, loadingTitle: ProcessFailed, loadingDescription: message, extraInfo: ServerError, opacity: 0.3 });
            let { customer } = this.state;
            customer.image = data;
            let selfImageUrl = ClientConfig.API_ENDPOINT + `/render-image?key=` + data.key;
            return this.setState({ showLoading: false, opacity: 1, customer: customer, customerImageUrl: selfImageUrl });
        } catch (error) {
            e.target.type = 'text';
            e.target.type = 'file';
            return this.setState({ showCloseButton: true, showLoadingSpinner: false, showLoading: true, loadingTitle: ProcessFailed, loadingDescription: error.message, extraInfo: UnexpectedError, opacity: 0.3 });
        }
    }

    removeImage = () => {
        let { customer } = this.state;
        customer.image = undefined;
        return this.setState({ customerImageUrl: undefined, customer: customer });
    }

    validateForm = () => {
        let { name, address, email, date, mobileNumber, customerId } = this.state.customer;
        if (!name.length || !address.length || !email.length || !date.length || !mobileNumber.length || !customerId.length || !this.state.customer.package)
            return true;
        return false;
    }

    saveCustomer = async (e) => {
        try {
            e.preventDefault();

            if (this.validateForm())
                throw new Error("Fill Form Properly To Add Customer, * Denotes Mandatory Fields !");
            this.setState({
                loadingTitle: PleaseWait,
                loadingDescription: CheckingInputs,
                showLoading: true,
                showLoadingSpinner: true,
                showCloseButton: false,
                extraInfo: DoNotRefresh,
                opacity: 0.3
            });
            let { customer } = this.state;
            if (customer.discount) {
                if (customer.discount.price > customer.package.price)
                    throw new Error(`Discount Price i.e ${customer.discount.price} Can Not Be More Then Package Price i.e ${customer.package.price}`);
            }
            customer.date = getUTCDate();
            let { code, message, result: { message: successMessage } = {} } = await postRequest("/web/admin/insert", { payload: { ...customer }, options: { uri: "/insert-customer" } }, uniquid());
            if (code > 300)
                throw new Error(message);
            this.setState({ loadingTitle: Success, loadingDescription: successMessage, showCloseButton: true, reload: true, showLoadingSpinner: false });
        } catch (error) {
            return this.setState({
                showLoading: true, loadingTitle: ProcessFailed,
                loadingDescription: error.message,
                extraInfo: UnexpectedError,
                showLoadingSpinner: false,
                showCloseButton: true,
                opacity: 0.3
            });
        }
    }

    setCustomerDetails = (key, value) => {
        let { customer } = this.state;
        customer[key] = value;
        return this.setState({ customer: customer });
    }

    async componentDidMount() {
        if (this.props.token) {
            try {
                this.setState({
                    showLoading: true,
                    showCloseButton: false,
                    showLoadingSpinner: true,
                    loadingTitle: PleaseWait,
                    loadingDescription: "Getting Available Packages And Discount, It May Take A While !",
                    opacity: 0.3,
                    extraInfo: DoNotRefresh
                });
                let { result: { records: { packages, discounts } } = { records: {} }, code, message } = await postRequest("/web/admin/query", { payload: {}, options: { uri: "/customer-form" } }, uniquid());

                if (code > 300)
                    throw new Error(message);

                return this.setState({ packages: packages, discounts: discounts, showLoading: false, opacity: 1 });
            } catch (error) {
                this.setState({
                    showCloseButton: true,
                    showLoadingSpinner: false,
                    loadingTitle: ProcessFailed,
                    extraInfo: UnexpectedError,
                    loadingDescription: error.message
                });
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <div style={{ opacity: this.state.opacity }}>

                    <div className="jumbotron jumbotron-fluid mt-0 mb-0 pb-0 pt-1 bg-white">

                        <div className="container-fluid pb-5">

                            <GoBackComponent {...this.props} />

                            <p className="text-center text-info font-weight-bold">Fill Form To Add Customer</p>

                            <form onSubmit={this.saveCustomer}>

                                <div className="form-row mt-4">
                                    <div className="form-group col-md-6">
                                        <LableComponent
                                            htmlFor="customerImage"
                                            classes="font-weight-bold"
                                            labelContent="Customer Image"
                                        />
                                        {!this.state.customer.image ?
                                            <div className="custom-file">

                                                <input type="file"
                                                    className="custom-file form-control"
                                                    id="customerImage"
                                                    onChange={this.uploadImage}
                                                    accept="image/*"
                                                    area-labelledby="customerImageHelp"
                                                />

                                                <LableComponent
                                                    htmlFor="customerImage"
                                                    classes="custom-file-label"
                                                    labelContent="Customer's Photo"
                                                />

                                                <SmallComponent
                                                    id="customerImageHelp"
                                                    classes="form-text text-danger font-weight-bold"
                                                    content="Select Or Capture Customer's Photo"
                                                />

                                            </div> :
                                            <div className="card">
                                                <img src={this.state.customerImageUrl} className="card-img-top w-50 ml-auto mr-auto" alt="" />
                                                <div className="card-body">
                                                    <button onClick={this.removeImage} className="btn btn-danger btn-sm w-100" id="imageUrl"><i class="fas fa-trash"></i> Remove</button>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <LableComponent
                                            htmlFor="name"
                                            classes="font-weight-bold"
                                            labelContent="Customer Name *"
                                        />

                                        <TextInputComponent
                                            autoComplete="nope"
                                            classes="form-control"
                                            id="name"
                                            areaDescribedby="customerName"
                                            placeholder="Name Of Customer"
                                            pipeToParent={this.setCustomerDetails}
                                            field="name"
                                            allowSpaces={true}
                                            titleCase={true}
                                            required={true}
                                        />

                                        <SmallComponent
                                            id="customerName"
                                            classes="form-text font-weight-bold text-danger"
                                            content="Fill Name Of Customer !"
                                        />

                                    </div>
                                </div>

                                <div className="form-row mt-2">
                                    <div className="form-group col-md-6">
                                        <LableComponent
                                            htmlFor="mobileNumber"
                                            classes="font-weight-bold text-dark"
                                            labelContent="Mobile Number *"
                                        />

                                        <NumberInputComponent
                                            autoComplete="nope"
                                            classes="form-control"
                                            id="mobileNumber"
                                            areaDescribedby="customerMobileNumber"
                                            placeholder="Customer's Mobile No."
                                            pipeToParent={this.setCustomerDetails}
                                            field="mobileNumber"
                                            required={true}
                                        />

                                        <SmallComponent
                                            id="customerMobileNumber"
                                            classes="form-text font-weight-bold text-danger"
                                            content="Fill Mobile No. Of Customer !"
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <LableComponent
                                            htmlFor="joiningDate"
                                            classes="font-weight-bold text-dark"
                                            labelContent="Joining Date *"
                                        />

                                        <DateInputComponent
                                            id="joiningDate"
                                            classes="form-control"
                                            pipeToParent={this.setCustomerDetails}
                                            field="date"
                                            areaDescribedby="customerJoiningDate"
                                            required={true}
                                        />

                                        <SmallComponent
                                            id="customerJoiningDate"
                                            classes="form-text font-weight-bold text-danger"
                                            content="Joining Date Of Customer !"
                                        />
                                    </div>
                                </div>

                                <div className="form-row mt-2">
                                    <div className="form-group col-md-6">
                                        <LableComponent
                                            htmlFor="customerId"
                                            classes="font-weight-bold"
                                            labelContent="Customer ID *"
                                        />

                                        <TextInputComponent
                                            autoComplete="nope"
                                            classes="form-control"
                                            id="customerId"
                                            areaDescribedby="custId"
                                            placeholder="Customer ID"
                                            pipeToParent={this.setCustomerDetails}
                                            field="customerId"
                                            allowSpaces={true}
                                            titleCase={true}
                                            required={true}
                                        />

                                        <SmallComponent
                                            id="custId"
                                            classes="form-text font-weight-bold text-danger"
                                            content="Fill Unique ID Customer !"
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <LableComponent
                                            htmlFor="emailId"
                                            classes="font-weight-bold"
                                            labelContent="Customer E-mail *"
                                        />

                                        <TextInputComponent
                                            autoComplete="nope"
                                            classes="form-control"
                                            id="emailId"
                                            areaDescribedby="customerEmail"
                                            placeholder="Customer's E-mail ID"
                                            pipeToParent={this.setCustomerDetails}
                                            field="email"
                                            allowSpaces={false}
                                            titleCase={false}
                                            required={true}
                                        />

                                        <SmallComponent
                                            id="customerEmail"
                                            classes="form-text font-weight-bold text-danger"
                                            content="Fill Customer's E-mail ID !"
                                        />
                                    </div>
                                </div>

                                <div className="form-row mt-2">
                                    <div className="form-group col-md-6">
                                        <LableComponent
                                            htmlFor="package"
                                            classes="font-weight-bold"
                                            labelContent="Allot Package *"
                                        />

                                        <SelectInputComponent
                                            autoComplete="nope"
                                            classes="form-control"
                                            id="package"
                                            areaDescribedby="packageId"
                                            pipeToParent={this.setCustomerDetails}
                                            field="package"
                                            array={this.state.packages}
                                            isObject={true}
                                            defaultOption="--Select Package-"
                                            required={true}
                                        />

                                        <SmallComponent
                                            id="packageId"
                                            values="form-text font-weight-bold text-danger"
                                            content="Assign A Package To Customer !"
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <LableComponent
                                            htmlFor="discount"
                                            classes="font-weight-bold"
                                            labelContent="Alloat Discount Package"
                                        />

                                        <SelectInputComponent
                                            autoComplete="nope"
                                            classes="form-control"
                                            id="discount"
                                            areaDescribedby="discountId"
                                            pipeToParent={this.setCustomerDetails}
                                            field="discount"
                                            isObject={true}
                                            defaultOption="--Select Discount--"
                                            array={this.state.discounts}
                                        />

                                        <SmallComponent
                                            id="discountId"
                                            classes="form-text font-weight-bold text-danger"
                                            content="Assign A Discount To Customer If It's Applicable !"
                                        />
                                    </div>

                                </div>

                                <div className="form-row mt-2">
                                    <div className="form-group col-md-6">
                                        <LableComponent
                                            htmlFor="discount"
                                            classes="font-weight-bold"
                                            labelContent="Fee Paid"
                                        />

                                        <SwitchInputComponent
                                            label="Yes"
                                            defaultState={true}
                                            pipeToParent={this.setCustomerDetails}
                                            field="isFeePaid"
                                        />

                                        <SmallComponent
                                            id="discountId"
                                            classes="form-text font-weight-bold text-danger"
                                            content="Turn Off If Customer Not Paying Fee Now !"
                                        />
                                    </div>
                                    <div className="form-group col-6">
                                        <LableComponent
                                            htmlFor="address"
                                            classes="font-weight-bold text-dark"
                                            labelContent="Address *"
                                        />

                                        <TextareaInputComponent
                                            autoComplete="nope"
                                            classes="form-control"
                                            id="address"
                                            rows="3"
                                            field="address"
                                            areaDescribedby="customerAddress"
                                            placeholder="Address Of Customer"
                                            pipeToParent={this.setCustomerDetails}
                                            allowSpaces={true}
                                            titleCase={true}
                                            required={true}
                                        />

                                        <SmallComponent
                                            id="customerAddress"
                                            classes="form-text font-weight-bold text-danger"
                                            content="Fill Address Of Customer !"
                                        />
                                    </div>
                                </div>
                                <div className="py-4">
                                    <div className="col-12 text-center">
                                        <button type="submit" className="btn btn-primary font-weight-bold text-white w-75" >Create Customer</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {this.state.showLoading && (
                    <ApplicationBlocker
                        title={this.state.loadingTitle}
                        showCloseButton={this.state.showCloseButton}
                        unsetBusyState={this.unsetBusyState}
                        description={this.state.loadingDescription}
                        extraInfo={this.state.extraInfo}
                        showLoadingSpinner={this.state.showLoadingSpinner}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default AddCustomerForm;