import Axios from "axios";
import { encryptRequestPayload } from "../utils/Encryption";
var node_cryptojs = require("node-cryptojs-aes");
let CryptoJS = node_cryptojs.CryptoJS;
const Config = require("../config/ClientConfig")[process.env["REACT_APP_ENV"] || "PRODUCTION"];

// Axios.interceptors.response

export const postRequest = async (baseUrl, requestPayload, aesKey, doNotEncrypt) => {
  const headers = {};
  if (localStorage.getItem('token'))
    headers['x-auth-token'] = localStorage.getItem('token');
  try {
    if (!doNotEncrypt)
      requestPayload = await encryptRequestPayload(requestPayload, aesKey);
    let response = await Axios.post(
      Config.API_ENDPOINT + baseUrl,
      requestPayload, {
      validateStatus: (status) => {
        return status >= 200 && status <= 500;
      },
      headers: { ...headers }
    });

    if (!doNotEncrypt && typeof response?.data === 'string')
      return JSON.parse(
        CryptoJS.enc.Utf8.stringify(
          await CryptoJS.AES.decrypt(response.data, aesKey)
        )
      );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};