import React, { Component } from 'react';
import ApplicationBlocker from "../../reusables/loaders/ApplicationBlocker";
import { ProcessFailed, UnexpectedError, Success } from '../../config/CommonConstants';
import { LoggedOut } from '../../config/LogoutConstants';

class LogoutComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            loadingDescription: "",
            loadingTitle: "",
            showCloseButton: false,
            showLoadingSpinner: false,
            extraInfo: "",
            redirect: "",
        }
    }

    unsetBusyState = () => {
        if (this.state.redirect)
            return this.props.history.replace(this.state.redirect);
        return this.setState({ showLoading: false, redirect: "" });
    }

    async componentDidMount() {
        try {
            localStorage.removeItem('token');
            return this.setState({ showLoading: true, loadingTitle: Success, loadingDescription: LoggedOut, extraInfo: "", showLoadingSpinner: false, showCloseButton: true, redirect: "/login" });
        } catch (error) {
            return this.setState({ showLoading: true, loadingTitle: ProcessFailed, loadingDescription: error.message, extraInfo: UnexpectedError, showLoadingSpinner: false, showCloseButton: true, redirect: "/home/users" });
        }
    }

    render() {
        return (<React.Fragment>
            {this.state.showLoading && <ApplicationBlocker title={this.state.loadingTitle} showCloseButton={this.state.showCloseButton} unsetBusyState={this.unsetBusyState} description={this.state.loadingDescription} extraInfo={this.state.extraInfo} showLoadingSpinner={this.state.showLoadingSpinner} />}
        </React.Fragment>
        );
    }
}

export default LogoutComponent;