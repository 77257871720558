import React, { Component } from 'react'
import TableComponent from '../../../reusables/TableComponent';

export default class PacksComponent extends Component {
    // constructor(props) {
    //     super(props);
    // }

    navigateToForm = () => {
        return this.props.history.push({
            pathname: "/home/add-package",
            auth: true,
            user: this.props.user
        });
    }

    render() {
        return (
            <div>
                <div className="jumbotron jumbotron-fluid mt-0 mb-0 pb-0 pt-1 bg-white">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="d-grid gap-2 d-flex justify-content-end">
                                    <button className="btn btn-primary btn-sm me-md-2" type="button" onClick={this.navigateToForm}>
                                        <i className="fa fa-plus" aria-hidden="true"></i> Add Package</button>
                                </div>
                            </div>
                        </div>
                        <p className="text-muted">Available Discounts</p>
                        <div className="mt-1 p-0" style={{ height: "500px", overflowY: "scroll" }}>
                            <TableComponent
                                columns={["Name", "Duration", "Price"]}
                                fields={["name", "duration", "price"]}
                                details={{ uri: "/fetch-packages", searchUri: "/search-packages" }}
                                {...this.props}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
