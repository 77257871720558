import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';

export default class GoBackComponent extends Component {
    render() {
        return (
            <Button
                variant="contained"
                color="primary"
                startIcon={<KeyboardBackspace />}
                onClick={() => this.props.history.goBack()}
            >
                Back
            </Button>
        )
    }
}
