import React, { Component } from 'react'
import GoBackComponent from '../../reusables/utils/GoBackComponent';
import TextInputComponent from '../../reusables/inputs/TextInputComponent';
import LableComponent from '../../reusables/info/LableComponent';
import NumberInputComponent from "../../reusables/inputs/NumberInputComponent";
import TimePass from '../../reusables/loaders/TimePass';
import SmallComponent from '../../reusables/info/SmallComponent';
import TextareaInputComponent from '../../reusables/inputs/TextareaInputComponent';
import Resizer from 'react-image-file-resizer';
import ApplicationBlocker from "../../reusables/loaders/ApplicationBlocker";
import { postRequest } from "../../services/HttpService";
import uniquid from "uniqid";
import { Uploading, UploadingImage } from '../../config/AddCustomerConstants';
import { CheckingInputs, DoNotRefresh, PleaseWait, ProcessFailed, ServerError, Success, UnexpectedError } from '../../config/CommonConstants';
const ClientConfig = require("../../config/ClientConfig").DEVELOPMENT;

export default class EditDetailsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customer: undefined,
            showLoading: false,
            loadingDescription: "",
            loadingTitle: "",
            showCloseButton: false,
            showLoadingSpinner: false,
            extraInfo: "",
            opacity: 1,
            reload: false,
        }
    }

    resizeFile = (file, ext) => new Promise(resolve => {
        Resizer.imageFileResizer(file, 1280, 720, ext, 100, 0,
            uri => {
                resolve(uri);
            },
            'base64',
            500,
            500
        );
    });

    checkAuth = () => {
        if (!this.props.location.auth)
            return this.props.history.replace('/home/dashboard');
    }

    uploadImage = async (e) => {
        try {
            e.preventDefault();
            this.setState({ showCloseButton: false, showLoadingSpinner: true, showLoading: true, loadingTitle: Uploading, loadingDescription: UploadingImage, extraInfo: DoNotRefresh, opacity: 0.3 });
            let file = e.target.files[0];
            if (!file)
                throw new Error("You Was Supposed To Select A File !");
            if (((file.size / 1024) / 1024) > 12) {
                throw new Error(`Your Selected File Is Of ${(Math.round((file.size / 1024) / 1024))} MB. Any File Must Not Be Greater Then 12 MB !`);
            }
            let ext = file.name.split('.').pop().toLowerCase();
            if (ext !== 'png' && ext !== 'jpg' && ext !== 'jpeg')
                throw new Error(`Your Selected File Is A ${ext} File. Only png, jpeg, jpg File(s) Can Uploaded !`);
            let image = { fileName: file.name, type: file.type, extension: file.name.split('.')[1] };
            image.name = uniquid() + "PGH" + uniquid() + "." + image.extension;
            let base64 = await this.resizeFile(file, ext);
            image.content = base64.split(',')[1];
            let request = { payload: { image: image, token: this.props.token }, options: { uri: "/upload-image" } };
            let { data, code, message } = await postRequest('/admin/upload', request, uniquid());
            if (code === 417)
                return this.setState({ showCloseButton: true, showLoadingSpinner: false, showLoading: true, loadingTitle: ProcessFailed, loadingDescription: message, extraInfo: ServerError, opacity: 0.3 });
            let { customer } = this.state;
            customer.image = data;
            let selfImageUrl = ClientConfig.API_ENDPOINT + `/render-image?key=` + data.key;
            return this.setState({ showLoading: false, opacity: 1, customer: customer, customerImageUrl: selfImageUrl });
        } catch (error) {
            e.target.type = 'text';
            e.target.type = 'file';
            return this.setState({ showCloseButton: true, showLoadingSpinner: false, showLoading: true, loadingTitle: ProcessFailed, loadingDescription: error.message, extraInfo: UnexpectedError, opacity: 0.3 });
        }
    }

    removeImage = () => {
        let { customer } = this.state;
        customer.image = null;
        return this.setState({ customer: customer });
    }

    componentDidMount() {
        let { record = undefined } = this.props.location;
        if (record)
            return this.setState({ customer: record });
    }

    validateForm = () => {
        let { name, address, email, mobileNumber, customerId } = this.state.customer;
        if (!name.length || !address.length || !email.length || !mobileNumber.length || !customerId.length)
            return true;
        return false;
    }

    setCustomerDetails = (key, value) => {
        let { customer } = this.state;
        customer[key] = value;
        return this.setState({ customer: customer });
    }

    unsetBusyState = async () => {
        if (this.state.reload)
            return this.props.history.goBack();
        return this.setState({ showLoading: false, opacity: 1, redirect: "" });
    };

    updateDetails = async (e) => {
        e.preventDefault();
        try {
            if (this.validateForm())
                throw new Error("Fill Form Properly To Add Customer, * Denotes Mandatory Fields !");
            this.setState({
                loadingTitle: PleaseWait,
                loadingDescription: CheckingInputs,
                showLoading: true,
                showLoadingSpinner: true,
                showCloseButton: false,
                extraInfo: DoNotRefresh,
                opacity: 0.3
            });
            let { customer } = this.state;
            let { code, message } = await postRequest("/web/admin/update", { payload: { customer: customer, token: this.props.token }, options: { uri: "/update-customer-details" } }, uniquid());
            if (code === 417)
                throw new Error(message);
            this.setState({ loadingTitle: Success, loadingDescription: "Customer Details Updated Successfully !", showCloseButton: true, reload: true, showLoadingSpinner: false });
        } catch (error) {
            return this.setState({
                showLoading: true, loadingTitle: ProcessFailed,
                loadingDescription: error.message,
                extraInfo: UnexpectedError,
                showLoadingSpinner: false,
                showCloseButton: true,
                opacity: 0.3
            });
        }
    }

    render() {
        this.checkAuth();
        let { customer } = this.state;
        return (
            <React.Fragment>
                <div style={{ opacity: this.state.opacity }}>
                    <div className="jumbotron jumbotron-fluid mt-0 mb-0 pb-0 pt-1 bg-white">
                        <div className="container-fluid">
                            <GoBackComponent {...this.props} />
                            <p className="text-center text-info font-weight-bold h4">Edit Customer Details</p>
                            {customer ?
                                <form className="mt-5 pb-5" onSubmit={this.updateDetails}>
                                    <div className="form-row mt-4">
                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="customerImage"
                                                classes="font-weight-bold"
                                                labelContent="Customer Image"
                                            />
                                            {!customer.image ?
                                                <div className="custom-file">

                                                    <input type="file"
                                                        className="custom-file form-control"
                                                        id="customerImage"
                                                        onChange={this.uploadImage}
                                                        accept="image/*"
                                                        area-labelledby="customerImageHelp"
                                                    />

                                                    <LableComponent
                                                        htmlFor="customerImage"
                                                        classes="custom-file-label"
                                                        labelContent="Customer's Photo"
                                                    />

                                                    <SmallComponent
                                                        id="customerImageHelp"
                                                        classes="form-text text-danger font-weight-bold"
                                                        content="Select Or Capture Customer's Photo"
                                                    />

                                                </div> :
                                                <div className="card">
                                                    <img src={`${ClientConfig.API_ENDPOINT}/render-image?key=${customer.image.key}`} className="card-img-top w-50 ml-auto mr-auto" alt="" />
                                                    <div className="card-body">
                                                        <button onClick={this.removeImage} className="btn btn-danger btn-sm w-100" id="imageUrl"><i class="fas fa-trash"></i> Remove</button>
                                                    </div>
                                                </div>}

                                        </div>
                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="name"
                                                classes="font-weight-bold"
                                                labelContent="Customer Name"
                                            />

                                            <TextInputComponent
                                                autoComplete="nope"
                                                classes="form-control"
                                                id="name"
                                                areaDescribedby="customerName"
                                                placeholder="Name Of Customer"
                                                field="name"
                                                allowSpaces={true}
                                                titleCase={true}
                                                required={true}
                                                pipeToParent={this.setCustomerDetails}
                                                defaultValue={customer.name}
                                            />

                                            <SmallComponent
                                                id="customerName"
                                                classes="form-text font-weight-bold text-danger"
                                                content="Fill Name Of Customer !"
                                            />

                                        </div>
                                    </div>

                                    <div className="form-row mt-3">
                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="mobileNumber"
                                                classes="font-weight-bold text-dark"
                                                labelContent="Mobile Number *"
                                            />

                                            <NumberInputComponent
                                                autoComplete="nope"
                                                classes="form-control"
                                                id="mobileNumber"
                                                areaDescribedby="customerMobileNumber"
                                                placeholder="Customer's Mobile No."
                                                pipeToParent={this.setCustomerDetails}
                                                field="mobileNumber"
                                                required={true}
                                                defaultValue={customer.mobileNumber}
                                                minLength={10}
                                                maxLength={10}
                                            />

                                            <SmallComponent
                                                id="customerMobileNumber"
                                                classes="form-text font-weight-bold text-danger"
                                                content="Fill Mobile No. Of Customer !"
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="customerId"
                                                classes="font-weight-bold"
                                                labelContent="Customer ID *"
                                            />

                                            <TextInputComponent
                                                autoComplete="nope"
                                                classes="form-control"
                                                id="customerId"
                                                areaDescribedby="custId"
                                                placeholder="Customer ID"
                                                pipeToParent={this.setCustomerDetails}
                                                field="customerId"
                                                allowSpaces={true}
                                                titleCase={true}
                                                required={true}
                                                defaultValue={customer.customerId}
                                            />

                                            <SmallComponent
                                                id="custId"
                                                classes="form-text font-weight-bold text-danger"
                                                content="Fill Unique ID Customer !"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row mt-3">
                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="emailId"
                                                classes="font-weight-bold"
                                                labelContent="Customer E-mail *"
                                            />

                                            <TextInputComponent
                                                autoComplete="nope"
                                                classes="form-control"
                                                id="emailId"
                                                areaDescribedby="customerEmail"
                                                placeholder="Customer's E-mail ID"
                                                pipeToParent={this.setCustomerDetails}
                                                field="email"
                                                allowSpaces={false}
                                                titleCase={false}
                                                required={true}
                                                defaultValue={customer.email}
                                            />

                                            <SmallComponent
                                                id="customerEmail"
                                                classes="form-text font-weight-bold text-danger"
                                                content="Fill Customer's E-mail ID !"
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="address"
                                                classes="font-weight-bold text-dark"
                                                labelContent="Address *"
                                            />

                                            <TextareaInputComponent
                                                autoComplete="nope"
                                                classes="form-control"
                                                id="address"
                                                rows="3"
                                                field="address"
                                                areaDescribedby="customerAddress"
                                                placeholder="Address Of Customer"
                                                pipeToParent={this.setCustomerDetails}
                                                allowSpaces={true}
                                                titleCase={true}
                                                required={true}
                                                defaultValue={customer.address}
                                            />

                                            <SmallComponent
                                                id="customerAddress"
                                                classes="form-text font-weight-bold text-danger"
                                                content="Fill Address Of Customer !"
                                            />
                                        </div>
                                    </div>
                                    <div className="py-4">
                                        <div className="col-12 text-center">
                                            <button type="submit" className="btn btn-primary font-weight-bold text-white w-75" >Update Details</button>
                                        </div>
                                    </div>

                                </form>
                                :
                                <TimePass />
                            }
                        </div>
                    </div>
                </div>
                {this.state.showLoading && (
                    <ApplicationBlocker
                        title={this.state.loadingTitle}
                        showCloseButton={this.state.showCloseButton}
                        unsetBusyState={this.unsetBusyState}
                        description={this.state.loadingDescription}
                        extraInfo={this.state.extraInfo}
                        showLoadingSpinner={this.state.showLoadingSpinner}
                    />
                )}
            </React.Fragment>
        )
    }
}
