import React, { Component } from 'react'
import LableComponent from '../../../reusables/info/LableComponent';
import SmallComponent from '../../../reusables/info/SmallComponent';
import TextInputComponent from '../../../reusables/inputs/TextInputComponent';
import NumberInputComponent from "../../../reusables/inputs/NumberInputComponent";
import ApplicationBlocker from "../../../reusables/loaders/ApplicationBlocker";
import GoBackComponent from '../../../reusables/utils/GoBackComponent';
import { CheckingInputs, DoNotRefresh, PleaseWait, ProcessFailed, Success, UnexpectedError } from '../../../config/CommonConstants';
import { postRequest } from '../../../services/HttpService';
import uniquid from "uniqid";

export default class AddDiscountComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            discountDetails: {
                name: "",
                price: ""
            },
            opacity: 1,
            reload: false,
            showLoading: false,
            loadingDescription: "",
            loadingTitle: "",
            showCloseButton: false,
            loginFormOpacity: 1,
            showLoadingSpinner: false,
            extraInfo: "",
        }
    }

    unsetBusyState = async () => {
        if (this.state.reload)
            return window.location.reload();
        return this.setState({ showLoading: false, opacity: 1, redirect: "" });
    };


    setDiscountDetails = (key, value) => {
        let { discountDetails } = this.state;
        discountDetails[key] = value;
        return this.setState({ discountDetails: discountDetails });
    }

    validateForm = () => {
        let { name, price } = this.state.discountDetails;
        if (!name.length || !price.length)
            return true;
        return false;
    }

    saveDiscount = async (e) => {
        try {
            e.preventDefault();
            let { discountDetails } = this.state;
            if (this.validateForm())
                throw new Error("Please Fill Form Properly To Save The Discount !");
            this.setState({
                showLoading: true,
                showLoadingSpinner: true,
                loadingTitle: PleaseWait,
                loadingDescription: CheckingInputs,
                extraInfo: DoNotRefresh,
                opacity: 0.3
            });
            let { code, result: { message } } = await postRequest("/web/admin/insert", { payload: { ...discountDetails }, options: { uri: "/insert-discount" } }, uniquid());
            if (code > 300)
                throw new Error(message);
            this.setState({ loadingTitle: Success, loadingDescription: message, extraInfo: "", showLoadingSpinner: false, showCloseButton: true, reload: true })
        } catch (error) {
            return this.setState({
                showLoading: true, loadingTitle: ProcessFailed,
                loadingDescription: error.message,
                extraInfo: UnexpectedError,
                showLoadingSpinner: false,
                showCloseButton: true,
                opacity: 0.3
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ opacity: this.state.opacity }}>
                    <div className="jumbotron jumbotron-fluid mt-0 mb-0 pb-0 pt-1 bg-white">
                        <div className="container-fluid">
                            <GoBackComponent {...this.props} />
                            <p className="text-center text-info font-weight-bold">Fill Form To Add Discount</p>
                            <form onSubmit={this.saveDiscount}>
                                <div className="form-row">
                                    <div className="form-group font-weight-bold col-md-6">

                                        <LableComponent
                                            htmlFor="name"
                                            classes="font-weight-bold text-dark"
                                            labelContent="Discount Name*"
                                        />

                                        <TextInputComponent
                                            autoComplete="nope"
                                            classes="form-control"
                                            id="name"
                                            areaDescribedby="discountName"
                                            placeholder="Name Of Discount"
                                            pipeToParent={this.setDiscountDetails}
                                            field="name"
                                            allowSpaces={true}
                                            titleCase={true}
                                            required={true}
                                        />

                                        <SmallComponent
                                            id="discountName"
                                            classes="form-text font-weight-bold text-danger"
                                            content="Fill Name Of Discount !"
                                        />

                                    </div>
                                    <div className="form-group font-weight-bold col-md-6">

                                        <LableComponent
                                            htmlFor="price"
                                            classes="font-weight-bold text-dark"
                                            labelContent="Discount Price*"
                                        />

                                        <NumberInputComponent
                                            autoComplete="nope"
                                            classes="form-control"
                                            id="price"
                                            areaDescribedby="discountPrice"
                                            placeholder="Discount Price"
                                            pipeToParent={this.setDiscountDetails}
                                            field="price"
                                            required={true}
                                        />

                                        <SmallComponent
                                            id="discountPrice"
                                            classes="form-text font-weight-bold text-danger"
                                            content="Discount Price In Rupees !"
                                        />
                                    </div>
                                </div>
                                <div className="my-2 col-12 text-center">
                                    <button type="submit" className="btn btn-primary font-weight-bold text-white w-75" >Create Discount</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {this.state.showLoading && (
                    <ApplicationBlocker
                        title={this.state.loadingTitle}
                        showCloseButton={this.state.showCloseButton}
                        unsetBusyState={this.unsetBusyState}
                        description={this.state.loadingDescription}
                        extraInfo={this.state.extraInfo}
                        showLoadingSpinner={this.state.showLoadingSpinner}
                    />
                )}
            </React.Fragment>
        )
    }
}
