import React, { Component } from 'react';
import CardComponent from '../landing-page-cards/CardComponent';
import CardLoadingComponent from '../landing-page-cards/CardLoadingComponent';
import uniquid from "uniqid";
import { postRequest } from "../../services/HttpService.js";
import ApplicationBlocker from "../../reusables/loaders/ApplicationBlocker";
import TimePass from '../../reusables/loaders/TimePass';
import { ProcessFailed, UnexpectedError } from '../../config/CommonConstants';
// import BarChartComponent from '../../reusables/charts/BarChartComponent';
import { getUTCDate, yeasterdayDate } from '../../utils/DateUtilities';

class DashboardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            totalCustomers: undefined,
            totalPackages: undefined,
            totalDiscounts: undefined,
            totalSubscriptions: undefined,
            totalActiveSubscriptions: undefined,
            totalTodayExpiringSubscription: undefined,
            totalCollectedAmount: undefined,
            totalOutstandingAmount: undefined,
            earning: undefined,
            outStanding: undefined,
            showLoading: false,
            loadingDescription: "",
            loadingTitle: "",
            showCloseButton: false,
            loginFormOpacity: 1,
            showLoadingSpinner: false,
            extraInfo: "",
            opacity: 1,
        }
    }
    async componentDidMount() {
        if (this.props.user) {
            try {
                let { result, code, message } = await postRequest("/web/admin/query", {
                    payload: {
                        currentDate: getUTCDate(),
                        yesterdayDate: yeasterdayDate()
                    },
                    options: { uri: "/fetch-dashboard-data" },
                }, uniquid());
                if (code > 300) {
                    throw new Error(message);
                }
                let { totalCustomers,
                    totalPackages,
                    totalDiscounts,
                    totalSubscriptions,
                    totalActiveSubscriptions,
                    totalTodayExpiringSubscription,
                    totalCollectedAmount,
                    totalOutstandingAmount,
                    outStanding,
                    earning } = result;
                return this.setState({
                    totalCustomers: totalCustomers,
                    totalPackages: totalPackages,
                    totalDiscounts: totalDiscounts,
                    totalSubscriptions: totalSubscriptions,
                    totalActiveSubscriptions: totalActiveSubscriptions,
                    totalTodayExpiringSubscription: totalTodayExpiringSubscription,
                    totalCollectedAmount: totalCollectedAmount,
                    totalOutstandingAmount: totalOutstandingAmount,
                    earning: earning,
                    outStanding: outStanding,
                    dataLoaded: true
                })
            } catch (error) {
                this.setState({
                    showLoading: true,
                    loadingTitle: ProcessFailed,
                    loadingDescription: error.message,
                    extraInfo: UnexpectedError,
                    showCloseButton: true,
                    showLoadingSpinner: false,
                    opacity: 0.3
                });
            }
        }
    }
    unsetBusyState = async () => {
        return this.setState({ showLoading: false, opacity: 1, redirect: "" });
    };

    render() {
        return (
            <React.Fragment>
                <div style={{ opacity: this.state.opacity }} className="mt-3">
                    {this.props.user ?
                        <div className="jumbotron jumbotron-fluid mt-0 mb-0 pb-0 pt-3 bg-white">
                            <div className="container-fluid pb-5">

                                <div className="row">
                                    <div className="col-md-4">
                                        {this.state.dataLoaded ? <CardComponent title="Total Customers" number={this.state.totalCustomers} /> : <CardLoadingComponent />}
                                    </div>
                                    <div className="col-md-4">
                                        {this.state.dataLoaded ? <CardComponent title="Total Discounts" number={this.state.totalDiscounts} /> : <CardLoadingComponent />}
                                    </div>
                                    <div className="col-md-4">
                                        {this.state.dataLoaded ? <CardComponent title="Total Packages" number={this.state.totalPackages} /> : <CardLoadingComponent />}
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-md-4">
                                        {this.state.dataLoaded ? <CardComponent title="Total Subscriptions" number={this.state.totalSubscriptions} /> : <CardLoadingComponent />}
                                    </div>
                                    <div className="col-md-4">
                                        {this.state.dataLoaded ? <CardComponent title="Total Active Subscriptions" number={this.state.totalActiveSubscriptions} /> : <CardLoadingComponent />}
                                    </div>
                                    <div className="col-md-4">
                                        {this.state.dataLoaded ? <CardComponent title="Subscriptions Expiring Today" number={this.state.totalTodayExpiringSubscription} /> : <CardLoadingComponent />}
                                    </div>
                                </div>

                                {/* <div className="row mt-2">
                                    <div className="col-md-6" >
                                        {this.state.dataLoaded ?
                                            <BarChartComponent
                                                title="Today's Finance"
                                                subTitle="Today's Outstanding And Earning"
                                                data={[
                                                    ["Date", "OutStanding", "Earning"],
                                                    [todayDateString(), this.state.outStanding[0].sum, this.state.earning[0].sum],
                                                    [yeasterdayDateString(), this.state.outStanding[1]?.sum || 0, this.state.earning[1]?.sum || 0]
                                                ]}
                                                height="300px"
                                            />
                                            : <CardLoadingComponent />}
                                    </div>
                                </div>
            */}
                            </div>
                        </div>
                        : <TimePass />}
                </div>
                {this.state.showLoading && (
                    <ApplicationBlocker
                        title={this.state.loadingTitle}
                        showCloseButton={this.state.showCloseButton}
                        unsetBusyState={this.unsetBusyState}
                        description={this.state.loadingDescription}
                        extraInfo={this.state.extraInfo}
                        showLoadingSpinner={this.state.showLoadingSpinner}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default DashboardComponent;