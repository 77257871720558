import React, { Component } from 'react';

class DateInputComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ""
        }
    }

    handleOnChange = (e) => {
        return this.setState({ value: e.target.value });
    }

    handleOnBlur = () => {
        let { pipeToParent, field } = this.props;

        // insert one space after each word except first and last word when foucus goes out 
        if (pipeToParent)
            return this.props.pipeToParent(field, this.state.value);
    }

    componentDidMount() {
        let { defaultValue } = this.props;
        if (defaultValue) {
            let date = new Date(defaultValue);
            return this.setState({ value: date.toISOString().slice(0, 10) });
        }
    }

    render() {
        let { id, classes, required = false, autoComplete = "nope", disabled = false } = this.props;
        return (
            <input
                type="date"
                id={id}
                className={classes}
                required={required}
                autoComplete={autoComplete}
                value={this.state.value}
                onChange={this.handleOnChange}
                onBlur={this.handleOnBlur}
                disabled={disabled}
            />
        );
    }
}

export default DateInputComponent;