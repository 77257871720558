import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export default class SwitchLabels extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: false
        }
    }

    handleChange = (e) => {
        return this.setState({ value: e.target.checked });
    }

    handleBlur = (e) => {
        let { pipeToParent = false, field } = this.props;
        if (pipeToParent) {
            return pipeToParent(field, this.state.value);
        }
    }

    componentDidMount() {
        let { defaultState = false } = this.props;
        return this.setState({ value: defaultState });
    }

    render() {
        let { value } = this.state;
        let { label } = this.props;
        return (
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={value}
                            onChange={this.handleChange}
                            name="checkbox"
                            color="primary"
                            onBlur={this.handleBlur}
                        />
                    }
                    label={label}
                />
            </FormGroup>
        );
    }
}
