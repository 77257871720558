import React, { Component } from 'react'
import TableLoaderComponent from "./loaders/TableLoaderComponent";
import { postRequest } from "../services/HttpService";
import uniquid from "uniqid";
const Config = require("../config/ClientConfig").DEVELOPMENT;


export default class TableComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wasDataFetched: false,
            array: [],
            skip: 0
        }
    }

    renderList = () => {
        switch (this.state.wasDataFetched) {
            case false: {
                return <tr>
                    <td key={uniquid()} colSpan={this.props?.columns?.length + 2 || 1}>
                        <TableLoaderComponent />
                    </td>
                </tr>
            }
            case true: {
                let { array } = this.state;
                let { fields = [], showActions = false, actions = [] } = this.props;
                return array.map((object) => {
                    return <tr key={uniquid()}>
                        {showActions ?
                            <td key={uniquid()} className="vertical-middle-aligned" >
                                <div className="dropdown">
                                    <button className="btn btn-primary btn-sm dropdown-toggle shadow-none" type="button" id={object["_id"]} data-bs-toggle="dropdown" aria-expanded="false">
                                        Actions
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby={object["_id"]} >
                                        {actions.map((action) => {
                                            let validator = action.condition ? action.condition(object) : true;
                                            return validator ? <li className="mouse" key={uniquid()}>
                                                <small
                                                    className='font-weight-bold dropdown-item'
                                                    style={{ fontSize: ".9rem" }}
                                                    onClick={() => action.onClickFunction(object)}
                                                    key={uniquid()}
                                                >
                                                    {action.label}
                                                </small>
                                            </li>
                                                : false
                                        })}
                                    </ul>
                                </div>

                            </td>
                            :
                            false
                        }
                        {
                            fields.map((field, i) => {
                                if (field === "image") {
                                    if (object[field])
                                        return <td key={uniquid()}> <img src={`${Config.API_ENDPOINT}/render-image?key=${object[field].key}`} className="img-fluid rounded d-block image-res" alt="" onError={(e) => e.target.src = "/assets/defaultUser.png"} width="70" height="70" /></td>
                                    return <td key={uniquid()}> <img src={`/assets/defaultUser.png`} className="img-fluid rounded d-block image-res" alt="" onError={(e) => e.target.src = "/assets/defaultUser.png"} width="70" height="70" /></td>
                                }

                                if (field.toLowerCase().includes("date"))
                                    return <td key={uniquid()} className="vertical-middle-aligned">{object[field] ? new Date(object[field]).toLocaleDateString() : ""}</td>

                                field = field.split(".");
                                let temp = object;
                                for (let i = 0; i < field.length; i++) {
                                    if (!temp.hasOwnProperty(field[i])) {
                                        temp = "";
                                        break;
                                    }
                                    temp = temp[field[i]];
                                }

                                if (i === fields.length - 1) {
                                    return <td key={uniquid()} className="vertical-middle-aligned" colSpan="2">{temp}</td>
                                }
                                return <td key={uniquid()} className="vertical-middle-aligned">{temp}</td>
                            })
                        }
                    </tr >
                })
            }
            default: {
                return <tr>
                    <td key={uniquid()}>
                        <TableLoaderComponent />
                    </td >
                </tr >
            }
        }
    }

    performSearch = async (e) => {
        e.preventDefault();
        try {
            this.setState({ wasDataFetched: false });
            let { result, code, message } = await postRequest("/web/admin/query", {
                payload: {
                    text: document.getElementById('searchBar').value
                },
                options: { uri: this.props.details.searchUri }
            }, uniquid());
            if (code !== 200)
                throw new Error(message);
            return this.setState({ array: result.records, wasDataFetched: true });
        } catch (error) {
            alert("Error >> " + error.message)
        }
    }

    async componentDidMount() {
        let { details, token } = this.props;
        if (token) {
            try {
                if (details?.uri) {
                    const { result, code, message } = await postRequest("/web/admin/query", { payload: { skip: this.state.skip }, options: { uri: details?.uri } }, uniquid());

                    if (code !== 200)
                        alert(message);

                    return this.setState({ wasDataFetched: true, array: result.records });
                }
            } catch (error) {
                alert(error);
            }
        }
    }

    render() {
        let { columns = [] } = this.props;
        return (
            <table className="table mb-5">
                <thead className="thead-light">
                    <tr>
                        {
                            this.props.showActions ?
                                <th key={uniquid()} scope="col" className="sticky-top">Actions</th> :
                                false
                        }
                        {columns.map((field) => {
                            return <th key={uniquid()} scope="col" className="sticky-top" style={{ minWidth: "150px" }}>{field}</th>
                        })}
                        <th key="static" scope="col" className="sticky-top">
                            <div className="d-grid d-flex justify-content-end ">
                                <form onSubmit={this.performSearch}>
                                    <div className="input-group" style={{ width: "220px" }}>
                                        <input type="search" id="searchBar" placeholder="Search" className="form-control form-control-sm" />
                                        <button type="button" className="btn btn-warning btn-sm" onClick={this.performSearch}>
                                            <i className="fas fa-search"></i>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderList()}
                </tbody>
            </table>
        )
    }
}
