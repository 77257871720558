import React, { Component } from 'react';
import GoBackComponent from '../../reusables/utils/GoBackComponent';
import TextInputComponent from '../../reusables/inputs/TextInputComponent';
import LableComponent from '../../reusables/info/LableComponent';
import NumberInputComponent from "../../reusables/inputs/NumberInputComponent";
import TimePass from '../../reusables/loaders/TimePass';
import DateInputComponent from '../../reusables/inputs/DateInputComponent';
import TextareaInputComponent from '../../reusables/inputs/TextareaInputComponent';
const Config = require("../../config/ClientConfig").DEVELOPMENT;

class ViewDetailsComponent extends Component {

    checkAuth = () => {
        if (!this.props.location.auth)
            return this.props.history.replace('/home/dashboard');
    }

    render() {
        this.checkAuth();
        let { record = undefined } = this.props.location;
        return (
            <div>
                <div className="jumbotron jumbotron-fluid mt-0 mb-0 pb-0 pt-1 bg-white">
                    <div className="container-fluid mb-5">
                        <GoBackComponent {...this.props} />
                        <p className="text-center text-info font-weight-bold h4">Full Details</p>
                        {record ?
                            <form className="mt-5 mb-5">
                                <div className="form-row mt-4">
                                    <div className="form-group col-md-6">
                                        <div>
                                            <img src={`${Config.API_ENDPOINT}/render-image?key=${record?.image?.key}`} className="img-fluid rounded d-block image-res w-25  mx-auto" alt="" onError={(e) => e.target.src = "/assets/defaultUser.png"} />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <LableComponent
                                            htmlFor="name"
                                            classes="font-weight-bold"
                                            labelContent="Customer Name"
                                        />

                                        <TextInputComponent
                                            autoComplete="nope"
                                            classes="form-control"
                                            id="name"
                                            areaDescribedby="customerName"
                                            placeholder="Name Of Customer"
                                            field="name"
                                            allowSpaces={true}
                                            titleCase={true}
                                            required={true}
                                            defaultValue={record.name}
                                            disabled={true}
                                        />

                                    </div>
                                </div>

                                <div className="form-row mt-3">
                                    <div className="form-group col-md-6">
                                        <LableComponent
                                            htmlFor="mobileNumber"
                                            classes="font-weight-bold text-dark"
                                            labelContent="Mobile Number *"
                                        />

                                        <NumberInputComponent
                                            autoComplete="nope"
                                            classes="form-control"
                                            id="mobileNumber"
                                            areaDescribedby="customerMobileNumber"
                                            placeholder="Customer's Mobile No."
                                            field="mobileNumber"
                                            required={true}
                                            defaultValue={record.mobileNumber}
                                            disabled={true}
                                        />

                                    </div>
                                    <div className="form-group col-md-6">
                                        <LableComponent
                                            htmlFor="emailId"
                                            classes="font-weight-bold"
                                            labelContent="Customer E-mail *"
                                        />

                                        <TextInputComponent
                                            autoComplete="nope"
                                            classes="form-control"
                                            id="emailId"
                                            areaDescribedby="customerEmail"
                                            placeholder="Customer's E-mail ID"
                                            required={true}
                                            defaultValue={record.email}
                                            disabled={true}
                                        />

                                    </div>
                                </div>

                                <div className="form-row mt-3">
                                    <div className="form-group col-md-6">
                                        <LableComponent
                                            htmlFor="customerId"
                                            classes="font-weight-bold"
                                            labelContent="Customer ID"
                                        />

                                        <TextInputComponent
                                            autoComplete="nope"
                                            classes="form-control"
                                            id="customerId"
                                            areaDescribedby="custId"
                                            placeholder="Customer ID"
                                            defaultValue={record.customerId}
                                            disabled={true}
                                        />


                                    </div>
                                    <div className="form-group col-md-6">
                                        <LableComponent
                                            htmlFor="joiningDate"
                                            classes="font-weight-bold text-dark"
                                            labelContent="Gym Joining Date *"
                                        />

                                        <DateInputComponent
                                            id="joiningDate"
                                            classes="form-control"
                                            areaDescribedby="customerJoiningDate"
                                            defaultValue={record.date}
                                            disabled={true}
                                        />

                                    </div>
                                </div>

                                <div className="form-row mt-3">
                                    <div className="form-group col-12">
                                        <LableComponent
                                            htmlFor="address"
                                            classes="font-weight-bold text-dark"
                                            labelContent="Address *"
                                        />

                                        <TextareaInputComponent
                                            autoComplete="nope"
                                            classes="form-control"
                                            id="address"
                                            rows="3"
                                            field="address"
                                            areaDescribedby="customerAddress"
                                            placeholder="Address Of Customer"
                                            disabled={true}
                                            defaultValue={record.address}
                                        />
                                    </div>
                                </div>

                            </form>
                            :
                            <TimePass />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewDetailsComponent;