import React, { Component } from 'react'

export default class TextInputComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: ""
        }
    }

    handleOnChange = (e) => {
        let value = e.target.value;
        const expression = /[^0-9]/;
        if (!expression.exec(value))
            return this.setState({ value: value });
    }

    handleOnBlur = (e) => {
        let value = e.target.value;
        let { pipeToParent, field } = this.props;
        this.setState({ value: value });
        if (pipeToParent)
            return this.props.pipeToParent(field, value);
    }

    componentDidMount() {
        let { defaultValue = undefined } = this.props;
        if (defaultValue) {
            return this.setState({ value: defaultValue });
        }
    }

    render() {
        let { disabled = false, minLength = "", maxLength = "" } = this.props;
        return (
            <input
                type="text"
                autoComplete={this.props.autoComplete}
                value={this.state.value}
                onChange={this.handleOnChange}
                onBlur={this.handleOnBlur}
                className={this.props.classes}
                id={this.props.id}
                aria-describedby={this.props.areaDescribedby || ""}
                placeholder={this.props.placeholder || ""}
                style={this.props.style}
                required={this.props.required ? this.props.required : false}
                disabled={disabled}
                minLength={minLength}
                maxLength={maxLength}
            />
        )
    }
}
