import React, { Component } from 'react'

export default class CardComponent extends Component {
    render() {
        let { title, number, prefix = "", suffix = "", classes = "" } = this.props;
        return (
            <div>
                <div className="card my-2" style={{ height: "200px" }}>
                    <div className="card-body">
                        <h5 className="card-title text-center text-info font-weight-bold">{title}</h5>
                        <br />
                        <p className={`${classes} card-text text-center text-danger font-weight-bold h1 `}>{prefix}{number}{suffix}</p>
                    </div>
                </div>
            </div>
        )
    }
}
