import XLSX from "xlsx";

export const generateExcelSheet = async ({
  fileName = "Export.xlsx",
  sheetName = "Sheet 1",
  columns = "",
  rows = [[]],
}) => {
  const workbook = XLSX.utils.book_new();

  var sheet = XLSX.utils.aoa_to_sheet(
    generateArray([resolveReportHeaders(columns)], rows)
  );

  sheet["!cols"] = generateWidth(resolveReportHeaders(columns), 30);

  sheet["!rows"] = generateHeight([...rows, []], 18);

  XLSX.utils.book_append_sheet(workbook, sheet, sheetName);

  XLSX.writeFile(workbook, fileName);
};

function resolveReportHeaders(choice) {
  switch (choice) {
    case "SETReportColumns": {
      return SETReportColumns;
    }
    case "ExportCustomersColumns": {
      return ExportCustomersColumns;
    }
    case "ExportPackagesColumns": {
      return ExportPackagesColumns;
    }
    case "ExportDiscountsColumns": {
      return ExportDiscountsColumns;
    }
    case "ExportPaymentsColumns": {
      return ExportPaymentsColumns;
    }
    default: {
      return [];
    }
  }
}

const SETReportColumns = [
  "Customer Name",
  "Customer ID",
  "Mobile Number",
  "Email",
  "Subscription Start Date",
  "Subscription End Date",
  "Fee Status",
  "Fee Amount",
  "Package Name",
];

const ExportCustomersColumns = [
  "Customer Name",
  "Customer ID",
  "Mobile Number",
  "Email",
  "Joining Date",
  "Address",
];

const ExportPackagesColumns = ["Package Name", "Duration (Months)", "Price"];

const ExportDiscountsColumns = ["Discount Name", "Price"];

const ExportPaymentsColumns = [
  "Customer Name",
  "Mobile Number",
  "Date",
  "Package Name",
  "Package Price",
  "Discount Name",
  "Discount Price",
  "Price Paid",
  "Subscription Started On",
  "Subscription Ended On",
];

function generateWidth(array, width) {
  let cols = [];
  array.forEach((element) => {
    cols.push({ wch: width });
  });
  return cols;
}

function generateHeight(array, height) {
  let rows = [];
  array.forEach((element) => {
    rows.push({ hpx: height });
  });
  return rows;
}

function generateArray(arrayToBeReturned = [], array = []) {
  array.forEach((record) => {
    arrayToBeReturned.push(record);
  });
  return arrayToBeReturned;
}
