import React, {Component} from 'react'
import TableComponent from '../../../reusables/TableComponent';

export default class DiscountsComponent extends Component {

    navigateToForm = () => {
        return this.props.history.push({
            pathname: "/home/add-discount",
            auth: true,
            user: this.props.user
        });
    }

    render() {
        return (
            <div>
                <div className="jumbotron jumbotron-fluid mt-0 mb-0 pb-0 pt-1 bg-white">
                    <div className="container-fluid">
                        <div className="d-grid gap-2 d-flex justify-content-end">
                            <button className="btn btn-primary btn-sm me-md-2" type="button"
                                    onClick={this.navigateToForm}>
                                <i className="fa fa-plus" aria-hidden="true"></i> Add Discount
                            </button>
                        </div>
                        <p className="text-muted">Available Discounts</p>
                        <div className="mt-1 p-0" style={{height: "500px", overflowY: "scroll"}}>
                            <TableComponent
                                columns={["Name", "Price"]}
                                fields={["name", "price"]}
                                details={{uri: "/fetch-discounts", searchUri: '/search-discounts'}}
                                {...this.props}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
