import React, { Component } from 'react'

export default class CardLoadingComponent extends Component {
    render() {
        return (
            <div>
                <div>
                    <div className="card my-2">
                        <div className="card-body" style={{ height: "200px" }}>
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
                                <div className="spinner-border text-primary" style={{ width: "3rem", height: "3rem" }} role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
