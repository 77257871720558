import React from "react";
import { Switch, Route } from "react-router-dom";
import HomeComponent from "./components/HomeComponent";
import "./App.css";
import LoginComponent from "./components/login/LoginComponent";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/login" component={LoginComponent} />
          <Route exact path="/home" component={HomeComponent} />
          <Route path="/" component={HomeComponent} />
        </Switch>
      </div>
    );
  }
}

export default App;