import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { postRequest } from "../services/HttpService";
import ApplicationBlocker from "../reusables/loaders/ApplicationBlocker";
import NavbarComponent from "./NavbarComponent";
import ConfigurationsComponent from "./configurations/ConfigurationsComponent";
import LogoutComponent from "./login/LogoutComponent";
import uniquid from "uniqid";
import { UnexpectedError } from "../config/CommonConstants";
import DashboardComponent from './dashboard/DashboardComponent'
import CustomersListComponent from './customers/CustomersListComponent'
import AddCustomerForm from './customers/AddCustomerForm'
import { TokenExpired } from "../config/SessionConstants";
import { ProcessFailed } from "../config/CommonConstants";
import AddPackageComponent from "./configurations/packages/AddPackageComponent";
import AddDiscountComponent from "./configurations/discounts/AddDiscountComponent";
import SubscriptionsListComponent from "./subscriptions/SubscriptionsListComponent";
import AddSubscriptionComponent from "./subscriptions/AddSubscriptionComponent";
import ViewDetailsComponent from "./customers/ViewDetailsComponent";
import EditDetailsComponent from "./customers/EditDetailsComponent";
import FooterComponent from "./FooterComponent";
import ViewFullDetailsComponent from "./subscriptions/ViewFullDetailsComponent";
import AddPaymentComponent from "./subscriptions/AddPaymentComponent";
import ReportsComponent from "./reports/ReportsComponent";

class HomeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      user: "",
      showLoading: false,
      loadingDescription: "",
      loadingTitle: "",
      showCloseButton: false,
      loginFormOpacity: 1,
      showLoadingSpinner: false,
      extraInfo: "",
      opacity: 1,
      redirect: "",
    };
  }

  setLoading = (title, description, moreInfo, closeButton, showLoadingSpinner) => {
    return this.setState({ opacity: 0.3, showLoading: true, showCloseButton: closeButton, loadingTitle: title, loadingDescription: description, extraInfo: moreInfo, showLoadingSpinner: showLoadingSpinner });
  }

  unsetBusyState = async () => {
    if (this.state.redirect && this.state.redirect.length) {
      localStorage.removeItem("token");
      return this.props.history.replace(this.state.redirect);
    }
    return this.setState({ showLoading: false, opacity: 1, redirect: "" });
  };

  async componentDidMount() {
    let token = localStorage.getItem("token");
    if (!token) {
      return this.props.history.replace("/login");
    }
    try {
      let { result: data, code, message } = await postRequest("/auth", {
        payload: { token: token },
        options: { uri: "/verify-user" },
      }, uniquid());
      if (code && code !== 200) {
        localStorage.removeItem('token');
        return this.setState({
          showLoading: true,
          loadingTitle: ProcessFailed,
          loadingDescription: message,
          extraInfo: TokenExpired,
          showLoadingSpinner: false,
          showCloseButton: true,
          redirect: "/login",
        });
      }
      return this.setState({ user: data.user, token: token, redirect: "" });
    } catch (error) {
      return this.setState({
        showLoading: true,
        loadingTitle: ProcessFailed,
        loadingDescription: error.message,
        extraInfo: UnexpectedError,
        showLoadingSpinner: false,
        showCloseButton: true,
      });
    }
  }


  render() {
    return (
      <React.Fragment>
        <div style={{ opacity: this.state.opacity }}>
          <NavbarComponent user={this.state.user} />
          <div style={{ position: "relative", top: "4rem" }}>
            <Switch>
              <Route
                exact
                path="/home/dashboard"
                component={() => (
                  <DashboardComponent {...this.props} user={this.state.user} token={this.state.token} unsetLoading={this.unsetBusyState} setLoading={this.setLoading} />
                )}
              />
              <Route
                exact
                path="/home/customers"
                component={() => (
                  <CustomersListComponent {...this.props} user={this.state.user} token={this.state.token} unsetLoading={this.unsetBusyState} setLoading={this.setLoading} />
                )}
              />

              <Route
                exact
                path="/home/subscriptions"
                component={() => (
                  <SubscriptionsListComponent
                    {...this.props} user={this.state.user}
                    token={this.state.token}
                  />
                )}
              />

              <Route path="/home/add-package"
                component={() =>
                  <AddPackageComponent
                    user={this.state.user}
                    token={this.state.token}
                    {...this.props}
                  />
                }
              />

              <Route path="/home/add-subscription"
                component={() =>
                  <AddSubscriptionComponent
                    user={this.state.user}
                    token={this.state.token}
                    {...this.props}
                  />
                }
              />

              <Route path="/home/add-payment"
                component={() =>
                  <AddPaymentComponent
                    user={this.state.user}
                    token={this.state.token}
                    {...this.props}
                  />
                }
              />


              <Route path="/home/view-details"
                component={() =>
                  <ViewDetailsComponent
                    user={this.state.user}
                    token={this.state.token}
                    {...this.props}
                  />
                }
              />

              <Route path="/home/view-subscription-details"
                component={() =>
                  <ViewFullDetailsComponent
                    user={this.state.user}
                    token={this.state.token}
                    {...this.props}
                  />
                }
              />

              <Route path="/home/edit-details"
                component={() =>
                  <EditDetailsComponent
                    user={this.state.user}
                    token={this.state.token}
                    {...this.props}
                  />
                }
              />


              <Route path="/home/add-discount"
                component={() =>
                  <AddDiscountComponent
                    user={this.state.user}
                    token={this.state.token}
                    {...this.props}
                  />
                }
              />


              <Route path="/home/add-customer-form"
                component={() =>
                  <AddCustomerForm
                    user={this.state.user}
                    token={this.state.token}
                    {...this.props}
                  />
                }
              />
              <Route
                path="/home/configurations"
                component={() => (
                  <ConfigurationsComponent
                    {...this.props}
                    user={this.state.user}
                    token={this.state.token}
                  />
                )}
              />

              <Route
                path="/home/reports"
                component={() => (
                  <ReportsComponent
                    {...this.props}
                    user={this.state.user}
                    token={this.state.token}
                  />
                )}
              />

              <Route
                exact
                path="/home/logout"
                component={() => (
                  <LogoutComponent
                    user={this.state.user}
                    token={this.state.token}
                    {...this.props}
                  />
                )}
              />
              <Redirect from="/*" to="/home/dashboard" />
            </Switch>

          </div>
        </div>
        {this.state.showLoading && (
          <ApplicationBlocker
            title={this.state.loadingTitle}
            showCloseButton={this.state.showCloseButton}
            unsetBusyState={this.unsetBusyState}
            description={this.state.loadingDescription}
            extraInfo={this.state.extraInfo}
            showLoadingSpinner={this.state.showLoadingSpinner}
          />
        )}
        <FooterComponent />
      </React.Fragment>
    );
  }
}

export default HomeComponent;
