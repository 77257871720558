import React, { Component } from 'react'
import LableComponent from '../../../reusables/info/LableComponent';
import SmallComponent from '../../../reusables/info/SmallComponent';
import TextInputComponent from '../../../reusables/inputs/TextInputComponent';
import NumberInputComponent from "../../../reusables/inputs/NumberInputComponent";
import ApplicationBlocker from "../../../reusables/loaders/ApplicationBlocker";
import GoBackComponent from '../../../reusables/utils/GoBackComponent';
import { CheckingInputs, DoNotRefresh, PleaseWait, ProcessFailed, Success, UnexpectedError } from '../../../config/CommonConstants';
import { postRequest } from "../../../services/HttpService";
import uniquid from "uniqid";

export default class AddPackageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            packageDetails: {
                name: "",
                duration: "",
                price: ""
            },
            opacity: 1,
            reload: false,
            showLoading: false,
            loadingDescription: "",
            loadingTitle: "",
            showCloseButton: false,
            loginFormOpacity: 1,
            showLoadingSpinner: false,
            extraInfo: "",
        }
    }

    unsetBusyState = async () => {
        if (this.state.reload)
            return window.location.reload();
        return this.setState({ showLoading: false, opacity: 1, redirect: "" });
    };

    setPackageDetails = (key, value) => {
        let { packageDetails } = this.state;
        packageDetails[key] = value;
        return this.setState({ packageDetails: packageDetails });
    }

    validateForm = () => {
        let { name, price, duration } = this.state.packageDetails;
        if (!name.length || !price.length || !duration.length)
            return true;
        return false;
    }

    savePackage = async (e) => {
        try {
            e.preventDefault();
            let { packageDetails } = this.state;
            if (this.validateForm())
                throw new Error("Please Fill Form Properly To Save The Package !");
            this.setState({
                showLoading: true,
                showLoadingSpinner: true,
                loadingTitle: PleaseWait,
                loadingDescription: CheckingInputs,
                extraInfo: DoNotRefresh,
                opacity: 0.3
            });
            let { code, result: { message } } = await postRequest("/web/admin/insert", { payload: { ...packageDetails }, options: { uri: "/insert-package" } }, uniquid());
            if (code > 300)
                throw new Error(message);
            this.setState({ loadingTitle: Success, loadingDescription: message, extraInfo: "", showLoadingSpinner: false, showCloseButton: true, reload: true })
        } catch (error) {
            return this.setState({
                showLoading: true, loadingTitle: ProcessFailed,
                loadingDescription: error.message,
                extraInfo: UnexpectedError,
                showLoadingSpinner: false,
                showCloseButton: true,
                opacity: 0.3
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ opacity: this.state.opacity }}>
                    <div className="jumbotron jumbotron-fluid mt-0 mb-0 pb-0 pt-1 bg-white">
                        <div className="container-fluid">
                            <GoBackComponent {...this.props} />
                            <p className="text-center text-info font-weight-bold">Fill Form To Add Package</p>
                            <form onSubmit={this.savePackage}>
                                <div className="form-row">
                                    <div className="form-group font-weight-bold col-md-6">

                                        <LableComponent
                                            htmlFor="name"
                                            classes="font-weight-bold text-dark"
                                            labelContent="Package Name*"
                                        />

                                        <TextInputComponent
                                            autoComplete="nope"
                                            classes="form-control"
                                            id="name"
                                            areaDescribedby="packageName"
                                            placeholder="Name Of Package"
                                            pipeToParent={this.setPackageDetails}
                                            field="name"
                                            allowSpaces={true}
                                            titleCase={true}
                                            required={true}
                                        />

                                        <SmallComponent
                                            id="packageName"
                                            classes="form-text font-weight-bold text-danger"
                                            content="Fill Name Of Package !"
                                        />

                                    </div>
                                    <div className="form-group font-weight-bold col-md-6">

                                        <LableComponent
                                            htmlFor="duration"
                                            classes="font-weight-bold text-dark"
                                            labelContent="Package Duration*"
                                        />

                                        <NumberInputComponent
                                            autoComplete="nope"
                                            classes="form-control"
                                            id="duration"
                                            areaDescribedby="packageDuration"
                                            placeholder="Duration Of Package"
                                            pipeToParent={this.setPackageDetails}
                                            field="duration"
                                            required={true}
                                        />

                                        <SmallComponent
                                            id="packageName"
                                            classes="form-text font-weight-bold text-danger"
                                            content="Duration Of Package In Months !"
                                        />

                                    </div>
                                </div>
                                <div className="form-row mt-2">
                                    <div className="form-group font-weight-bold col-12">

                                        <LableComponent
                                            htmlFor="price"
                                            classes="font-weight-bold text-dark"
                                            labelContent="Package Price*"
                                        />

                                        <NumberInputComponent
                                            autoComplete="nope"
                                            classes="form-control"
                                            id="price"
                                            areaDescribedby="packagePrice"
                                            placeholder="Price Of Package"
                                            pipeToParent={this.setPackageDetails}
                                            field="price"
                                            required={true}
                                        />

                                        <SmallComponent
                                            id="packagePrice"
                                            classes="form-text font-weight-bold text-danger"
                                            content="Price Of Package In Rupees !"
                                        />

                                    </div>
                                </div>
                                <div className="my-2 col-12 text-center">
                                    <button type="submit" className="btn btn-primary font-weight-bold text-white w-75" >Create Package</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {
                    this.state.showLoading && (
                        <ApplicationBlocker
                            title={this.state.loadingTitle}
                            showCloseButton={this.state.showCloseButton}
                            unsetBusyState={this.unsetBusyState}
                            description={this.state.loadingDescription}
                            extraInfo={this.state.extraInfo}
                            showLoadingSpinner={this.state.showLoadingSpinner}
                        />
                    )
                }
            </React.Fragment >
        )
    }
}
