import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Brand } from "../config/Constants";
class NavbarComponent extends Component {
  render() {
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
          <NavLink className="navbar-brand font-weight-bolder text-info" to="/home/dashboard">
            {Brand}
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarNav"
          >
            <ul className="navbar-nav ml-auto mx-2">
              <li className="nav-item mx-5">
                <NavLink className='nav-link' to="/home/customers">Customers</NavLink>
              </li>
              <li className="nav-item mx-5">
                <NavLink className='nav-link' to="/home/subscriptions">Subscriptions</NavLink>
              </li>
              <li className="nav-item mx-5">
                <NavLink className='nav-link' to="/home/configurations">Configurations</NavLink>
              </li>
              <li className="nav-item mx-5">
                <NavLink className='nav-link' to="/home/reports">Reports</NavLink>
              </li>
              <li className="nav-item mx-1">
                <div className="dropdown nav-link">
                  <a type="button" id="dropdownMenu2" data-toggle="dropdown"
                    aria-expanded="false" href="null"><i className="fas fa-ellipsis-v"></i></a>


                  <div className="dropdown-menu dropdown-menu-right dropdown-primary">
                    <NavLink className='nav-link' to="/home/logout"><i className="fa fa-external-link" aria-hidden="true"></i>
&nbsp;&nbsp;Logout</NavLink>
                  </div>
                </div>
                {/* <NavLink className='nav-link' to="/home/logout">Logout</NavLink> */}
              </li>
            </ul>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default NavbarComponent;
