import React, { Component } from 'react';
import TableComponent from '../../reusables/TableComponent';

class SubscriptionsListComponent extends Component {

    handleViewDetails = (row) => {
        return this.props.history.push({
            pathname: "/home/view-subscription-details",
            auth: true,
            user: this.props.user,
            record: row
        });
    }

    navigateToAddPayment = (row) => {
        return this.props.history.push({
            pathname: "/home/add-payment",
            auth: true,
            user: this.props.user,
            record: row
        });
    }

    render() {
        return (
            <div>
                <div className="jumbotron jumbotron-fluid mt-0 mb-0 pb-0 pt-4 bg-white">
                    <div className="container-fluid">
                        <p className="text-muted">Active Subscription(s)</p>
                        <div className="mt-1 p-0" style={{ height: "500px", overflow: "scroll" }}>
                            <TableComponent
                                columns={["Image", "Name", "Start Date", "End Date", "Price", "Status"]}
                                fields={["image", "customer.name", "startDate", "endDate", "price", "feePaid"]}
                                details={{ uri: "/fetch-subscriptions", searchUri: '/search-subscriptions' }}
                                showActions={true}
                                actions={[
                                    {
                                        label: "View Details",
                                        onClickFunction: this.handleViewDetails
                                    },
                                    {
                                        label: "Add Payment",
                                        onClickFunction: this.navigateToAddPayment,
                                        condition: (object) => !object.isFeePaid
                                    }
                                ]}
                                {...this.props}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SubscriptionsListComponent;