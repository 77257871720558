import React, { Component } from 'react'
import { toEqualSpaces, toTitleCase } from '../../utils/InputHelper';

export default class TextInputComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: ""
        }
    }

    handleOnChange = (e) => {
        let value = e.target.value;
        let { allowSpaces, regex } = this.props;

        if (regex) {
            if (!regex.exec(value))
                return this.setState({ value: value });
        }

        if (!allowSpaces) {
            const expression = /[^A-Za-z.@0-9-]/;
            if (!expression.exec(value))
                return this.setState({ value: value });
            value = this.state.value;
        }

        return this.setState({ value: value });
    }

    handleOnBlur = (e) => {
        let value = e.target.value;
        let { allowSpaces, titleCase, pipeToParent, field } = this.props;

        // insert one space after each word except first and last word when foucus goes out 
        if (allowSpaces) {
            value = toEqualSpaces(value);
        }

        // changes to title case when foucus goes out 
        if (titleCase) {
            value = toTitleCase(value);
        }

        this.setState({ value: value });
        if (pipeToParent)
            return this.props.pipeToParent(field, value);
    }

    componentDidMount() {
        let { defaultValue } = this.props;
        if (defaultValue)
            return this.setState({ value: defaultValue });
    }

    render() {
        let { disabled = false } = this.props;
        return (
            <input
                type="text"
                autoComplete={this.props.autoComplete}
                value={this.state.value}
                onChange={this.handleOnChange}
                onBlur={this.handleOnBlur}
                className={this.props.classes}
                id={this.props.id}
                aria-describedby={this.props.areaDescribedby || ""}
                placeholder={this.props.placeholder || ""}
                style={this.props.style}
                required={this.props.required ? this.props.required : false}
                disabled={disabled}
            />
        )
    }
}

