import React, { Component } from 'react'
import LableComponent from '../../reusables/info/LableComponent';
import SmallComponent from '../../reusables/info/SmallComponent';
import TextInputComponent from '../../reusables/inputs/TextInputComponent';
import NumberInputComponent from '../../reusables/inputs/NumberInputComponent';
import GoBackComponent from '../../reusables/utils/GoBackComponent'
import TimePass from '../../reusables/loaders/TimePass';
import SelectInputComponent from '../../reusables/inputs/SelectInputComponent';
import DateInputComponent from '../../reusables/inputs/DateInputComponent';
import ApplicationBlocker from "../../reusables/loaders/ApplicationBlocker";
import SwitchInputComponent from "../../reusables/inputs/SwitchInputComponent";
import { postRequest } from "../../services/HttpService";
import uniquid from "uniqid";
import { CheckingInputs, DoNotRefresh, PleaseWait, ProcessFailed, Success, UnexpectedError } from '../../config/CommonConstants';
import { getUTCDate } from '../../utils/DateUtilities';
const Config = require("../../config/ClientConfig").DEVELOPMENT;

export default class AddSubscriptionComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            packages: [],
            discounts: [],
            subscription: {
                package: undefined,
                discount: undefined,
                startDate: undefined,
                isFeePaid: true
            },
            showLoading: false,
            loadingDescription: "",
            loadingTitle: "",
            showCloseButton: false,
            loginFormOpacity: 1,
            showLoadingSpinner: false,
            extraInfo: "",
            opacity: 1,
            reload: false
        }
    }

    setSubscriptionDetails = (key, value) => {
        let { subscription } = this.state;
        subscription[key] = value;
        return this.setState({ subscription: subscription });
    }

    checkAuth = () => {
        if (!this.props.location.auth)
            return this.props.history.replace('/home/dashboard');
    }

    saveSubscription = async (e) => {
        e.preventDefault();
        try {
            if (this.validateForm())
                throw new Error("Fill Form Properly To Add Customer, * Denotes Mandatory Fields !");
            this.setState({
                loadingTitle: PleaseWait,
                loadingDescription: CheckingInputs,
                showLoading: true,
                showLoadingSpinner: true,
                showCloseButton: false,
                extraInfo: DoNotRefresh,
                opacity: 0.3
            });
            let { subscription } = this.state;
            let { package: pack, discount = undefined, startDate } = subscription;
            subscription["price"] = pack.price;
            if (discount) {
                if (pack.price < discount.price)
                    throw new Error(`Discount Price i.e ${discount.price} Can Not Be More Then Package Price i.e ${pack.price}`);
                else
                    subscription["price"] = pack.price - discount.price;

            }
            let { record } = this.props.location;
            subscription["customer"] = { _id: record._id, name: record.name, mobileNumber: record.mobileNumber };
            subscription["image"] = record.image || null;
            subscription.endDate = new Date(startDate).setMonth(new Date(startDate).getMonth() + pack.duration);
            subscription["date"] = getUTCDate();
            let { code, message } = await postRequest("/admin/insert", { payload: { subscriptionDetails: subscription, token: this.props.token }, options: { uri: "/insert-subscription" } }, uniquid());
            if (code > 300)
                throw new Error(message);
            this.setState({ loadingTitle: Success, loadingDescription: "Subscription Created Successfully !", showCloseButton: true, reload: true, showLoadingSpinner: false });
        } catch (error) {
            return this.setState({
                showLoading: true, loadingTitle: ProcessFailed,
                loadingDescription: error.message,
                extraInfo: UnexpectedError,
                showLoadingSpinner: false,
                showCloseButton: true,
                opacity: 0.3
            });
        }
    }

    unsetBusyState = async () => {
        if (this.state.reload)
            return this.props.history.goBack();
        return this.setState({ showLoading: false, opacity: 1, redirect: "" });
    };

    validateForm = () => {
        let { startDate, package: pack } = this.state.subscription;
        if (!startDate || !pack || !pack._id || !pack.price || !pack.duration)
            return true;
        return false;
    }

    async componentDidMount() {
        if (this.props.token) {
            try {
                this.setState({
                    showLoading: true,
                    showCloseButton: false,
                    showLoadingSpinner: true,
                    loadingTitle: PleaseWait,
                    loadingDescription: "Getting Available Packages And Discount, It May Take A While !",
                    opacity: 0.3,
                    extraInfo: DoNotRefresh
                });
                let { result: { records: { packages, discounts } }, code, message } = await postRequest("/web/admin/query", { payload: { token: this.props.token }, options: { uri: "/customer-form" } }, uniquid());
                if (code > 417)
                    throw new Error(message);
                return this.setState({ packages: packages, discounts: discounts, showLoading: false, opacity: 1 });
            } catch (error) {
                this.setState({
                    showCloseButton: true,
                    showLoadingSpinner: false,
                    loadingTitle: ProcessFailed,
                    extraInfo: UnexpectedError,
                    loadingDescription: error.message
                });
            }
        }
    }

    render() {
        this.checkAuth();
        let { record = undefined } = this.props.location;
        return (
            <React.Fragment>
                <div style={{ opacity: this.state.opacity }}>
                    <div className="jumbotron jumbotron-fluid mt-0 mb-0 pb-0 pt-1 bg-white">
                        <div className="container-fluid pb-5">
                            <GoBackComponent {...this.props} />
                            <p className="text-center text-info font-weight-bold">Fill Form To Add Subscription</p>
                            {record ?
                                <form onSubmit={this.saveSubscription}>
                                    <div className="form-row mt-4">
                                        <div className="form-group col-md-6">
                                            <div>
                                                <img src={`${Config.API_ENDPOINT}/render-image?key=${record?.image?.key}`} className="img-fluid rounded d-block image-res" alt="" onError={(e) => e.target.src = "/assets/defaultUser.png"} width="100" height="100" />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="name"
                                                classes="font-weight-bold"
                                                labelContent="Customer Name *"
                                            />

                                            <TextInputComponent
                                                autoComplete="nope"
                                                classes="form-control"
                                                id="name"
                                                areaDescribedby="customerName"
                                                placeholder="Name Of Customer"
                                                field="name"
                                                allowSpaces={true}
                                                titleCase={true}
                                                required={true}
                                                defaultValue={record.name}
                                                disabled={true}
                                            />

                                            <SmallComponent
                                                id="customerName"
                                                classes="form-text font-weight-bold text-danger"
                                                content="Auto Filled, Do Not Change !"
                                            />

                                        </div>
                                    </div>

                                    <div className="form-row mt-2">
                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="mobileNumber"
                                                classes="font-weight-bold text-dark"
                                                labelContent="Mobile Number *"
                                            />

                                            <NumberInputComponent
                                                autoComplete="nope"
                                                classes="form-control"
                                                id="mobileNumber"
                                                areaDescribedby="customerMobileNumber"
                                                placeholder="Customer's Mobile No."
                                                field="mobileNumber"
                                                required={true}
                                                disabled={true}
                                                defaultValue={record.mobileNumber}
                                            />

                                            <SmallComponent
                                                id="customerMobileNumber"
                                                classes="form-text font-weight-bold text-danger"
                                                content="Auto Filled, Do Not Change !"
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="customerId"
                                                classes="font-weight-bold"
                                                labelContent="Customer ID *"
                                            />

                                            <TextInputComponent
                                                autoComplete="nope"
                                                classes="form-control"
                                                id="customerId"
                                                areaDescribedby="custId"
                                                placeholder="Customer ID"
                                                pipeToParent={this.setCustomerDetails}
                                                field="customerId"
                                                allowSpaces={true}
                                                titleCase={true}
                                                required={true}
                                                defaultValue={record.customerId}
                                                disabled={true}
                                            />

                                            <SmallComponent
                                                id="custId"
                                                classes="form-text font-weight-bold text-danger"
                                                content="Auto Filled, Do Not Change !"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row mt-2">
                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="emailId"
                                                classes="font-weight-bold"
                                                labelContent="Customer E-mail *"
                                            />

                                            <TextInputComponent
                                                autoComplete="nope"
                                                classes="form-control"
                                                id="emailId"
                                                areaDescribedby="customerEmail"
                                                placeholder="Customer's E-mail ID"
                                                field="email"
                                                allowSpaces={false}
                                                titleCase={false}
                                                required={true}
                                                disabled={true}
                                                defaultValue={record.email}
                                            />

                                            <SmallComponent
                                                id="customerEmail"
                                                classes="form-text font-weight-bold text-danger"
                                                content="Auto Filled, Do Not Change !"
                                            />
                                        </div>

                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="joiningDate"
                                                classes="font-weight-bold text-dark"
                                                labelContent="Gym Joined Date"
                                            />

                                            <DateInputComponent
                                                id="joiningDate"
                                                classes="form-control"
                                                pipeToParent={this.setSubscriptionDetails}
                                                field="startDate"
                                                areaDescribedby="customerJoiningDate"
                                                required={true}
                                            />

                                            <SmallComponent
                                                id="customerJoiningDate"
                                                classes="form-text font-weight-bold text-danger"
                                                content="Start Date Of Subscription !"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row mt-2">
                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="package"
                                                classes="font-weight-bold"
                                                labelContent="Alloat Package *"
                                            />

                                            <SelectInputComponent
                                                autoComplete="nope"
                                                classes="form-control"
                                                id="package"
                                                areaDescribedby="packageId"
                                                pipeToParent={this.setSubscriptionDetails}
                                                field="package"
                                                array={this.state.packages}
                                                isObject={true}
                                                defaultOption="-- Select Package --"
                                                required={true}
                                            />

                                            <SmallComponent
                                                id="packageId"
                                                classes="form-text font-weight-bold text-danger"
                                                content="Assign A Package For Subscription !"
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="discount"
                                                classes="font-weight-bold"
                                                labelContent="Alloat Discount Package"
                                            />

                                            <SelectInputComponent
                                                autoComplete="nope"
                                                classes="form-control"
                                                id="discount"
                                                areaDescribedby="discountId"
                                                pipeToParent={this.setSubscriptionDetails}
                                                field="discount"
                                                isObject={true}
                                                defaultOption="--Select Discount--"
                                                array={this.state.discounts}
                                            />

                                            <SmallComponent
                                                id="discountId"
                                                classes="form-text font-weight-bold text-danger"
                                                content="Assign A Discount If It's Applicable !"
                                            />
                                        </div>


                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="discount"
                                                classes="font-weight-bold"
                                                labelContent="Fee Paid"
                                            />

                                            <SwitchInputComponent
                                                label="Yes"
                                                defaultState={true}
                                                pipeToParent={this.setSubscriptionDetails}
                                                field="isFeePaid"
                                            />

                                            <SmallComponent
                                                id="discountId"
                                                classes="form-text font-weight-bold text-danger"
                                                content="Turn Off If Customer Not Paying Fee Now !"
                                            />
                                        </div>

                                    </div>
                                    <div className="py-4">
                                        <div className="col-12 text-center">
                                            <button type="submit" className="btn btn-primary font-weight-bold text-white w-75" >Save Subscription</button>
                                        </div>
                                    </div>
                                </form>

                                :
                                <TimePass />
                            }
                        </div>
                    </div>
                </div >
                {this.state.showLoading && (
                    <ApplicationBlocker
                        title={this.state.loadingTitle}
                        showCloseButton={this.state.showCloseButton}
                        unsetBusyState={this.unsetBusyState}
                        description={this.state.loadingDescription}
                        extraInfo={this.state.extraInfo}
                        showLoadingSpinner={this.state.showLoadingSpinner}
                    />
                )}
            </React.Fragment>
        )
    }
}
