import React, { Component } from 'react'
import GoBackComponent from '../../reusables/utils/GoBackComponent';
import TextInputComponent from '../../reusables/inputs/TextInputComponent';
import LableComponent from '../../reusables/info/LableComponent';
import NumberInputComponent from "../../reusables/inputs/NumberInputComponent";
import TimePass from '../../reusables/loaders/TimePass';
import DateInputComponent from '../../reusables/inputs/DateInputComponent';
import SelectInputComponent from '../../reusables/inputs/SelectInputComponent';
import SmallComponent from '../../reusables/info/SmallComponent';
import ApplicationBlocker from "../../reusables/loaders/ApplicationBlocker";
import { postRequest } from "../../services/HttpService";
import uniquid from "uniqid";
import { CheckingInputs, DoNotRefresh, PleaseWait, ProcessFailed, Success, UnexpectedError } from '../../config/CommonConstants';
import { getUTCDate } from '../../utils/DateUtilities';

const Config = require("../../config/ClientConfig").DEVELOPMENT;

export default class AddPaymentComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            discounts: [],
            showLoading: false,
            loadingDescription: "",
            loadingTitle: "",
            showCloseButton: false,
            loginFormOpacity: 1,
            showLoadingSpinner: false,
            extraInfo: "",
            opacity: 1,
            reload: false,
            payment: {
                discount: undefined
            }
        }
    }


    checkAuth = () => {
        if (!this.props.location.auth)
            return this.props.history.replace('/home/dashboard');
    }

    unsetBusyState = async () => {
        if (this.state.reload)
            return this.props.history.goBack();
        return this.setState({ showLoading: false, opacity: 1, redirect: "" });
    };

    setPaymentDetails = (key, value) => {
        let { payment } = this.state;
        payment[key] = value;
        return this.setState({ payment: payment });
    }

    savePayment = async (e) => {
        e.preventDefault();
        try {
            this.setState({
                loadingTitle: PleaseWait,
                loadingDescription: CheckingInputs,
                showLoading: true,
                showLoadingSpinner: true,
                showCloseButton: false,
                extraInfo: DoNotRefresh,
                opacity: 0.3
            });

            let { payment } = this.state;
            let { package: pack, _id, customer, image = null } = this.props.location.record;
            payment["date"] = getUTCDate();
            payment["subscription"] = { _id: _id };
            payment["price"] = pack.price;
            payment["price"] = pack.price;
            payment.customer = customer;
            payment.image = image;

            let { discount = null } = payment;
            if (discount) {
                if (pack.price < discount.price)
                    throw new Error(`Discount Price i.e ${discount.price} Can Not Be More Then Package Price i.e ${pack.price}`);
                else
                    payment["price"] = pack.price - discount.price;
            }
            let subscriptionDetails = { _id: _id, discount: discount, price: payment.price }
            let { code, message } = await postRequest("/admin/insert", { payload: { paymentDetails: payment, subscriptionDetails: subscriptionDetails, token: this.props.token }, options: { uri: "/insert-payment" } }, uniquid());
            if (code > 300)
                throw new Error(message);
            this.setState({ loadingTitle: Success, loadingDescription: "Payment Created Successfully !", showCloseButton: true, reload: true, showLoadingSpinner: false });
        } catch (error) {
            return this.setState({
                showLoading: true, loadingTitle: ProcessFailed,
                loadingDescription: error.message,
                extraInfo: UnexpectedError,
                showLoadingSpinner: false,
                showCloseButton: true,
                opacity: 0.3
            });
        }
    }


    async componentDidMount() {
        if (this.props.token) {
            try {
                this.setState({
                    showLoading: true,
                    showCloseButton: false,
                    showLoadingSpinner: true,
                    loadingTitle: PleaseWait,
                    loadingDescription: "Getting Available Discount, It May Take A While !",
                    opacity: 0.3,
                    extraInfo: DoNotRefresh
                });
                let { result:{records}, code, message } = await postRequest("/admin/query", { payload: { token: this.props.token }, options: { uri: "/fetch-discounts" } }, uniquid());
                if (code > 300)
                    throw new Error(message);
                return this.setState({ discounts: records, showLoading: false, opacity: 1 });
            } catch (error) {
                this.setState({
                    showCloseButton: true,
                    showLoadingSpinner: false,
                    loadingTitle: ProcessFailed,
                    extraInfo: UnexpectedError,
                    loadingDescription: error.message
                });
            }
        }
    }

    render() {
        this.checkAuth();
        let { record = undefined } = this.props.location;
        return (
            <React.Fragment>
                <div style={{ opacity: this.state.opacity }}>
                    <div className="jumbotron jumbotron-fluid mt-0 mb-0 pb-0 pt-1 bg-white">
                        <div className="container-fluid pb-5">
                            <GoBackComponent {...this.props} />
                            <p className="text-center text-info font-weight-bold h4">Full Details</p>
                            {record ?
                                <form className="mt-5" onSubmit={this.savePayment}>
                                    <div className="form-row mt-4">
                                        <div className="form-group col-md-6">
                                            <div>
                                                <img src={`${Config.API_ENDPOINT}/render-image?key=${record?.image?.key}`} className="img-fluid rounded d-block image-res w-25  mx-auto" alt="" onError={(e) => e.target.src = "/assets/defaultUser.png"} />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="name"
                                                classes="font-weight-bold"
                                                labelContent="Customer Name"
                                            />

                                            <TextInputComponent
                                                autoComplete="nope"
                                                classes="form-control"
                                                id="name"
                                                areaDescribedby="customerName"
                                                placeholder="Name Of Customer"
                                                field="name"
                                                allowSpaces={true}
                                                titleCase={true}
                                                required={true}
                                                defaultValue={record.customer.name}
                                                disabled={true}
                                            />

                                        </div>
                                    </div>

                                    <div className="form-row mt-3">
                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="mobileNumber"
                                                classes="font-weight-bold text-dark"
                                                labelContent="Mobile Number *"
                                            />

                                            <NumberInputComponent
                                                autoComplete="nope"
                                                classes="form-control"
                                                id="mobileNumber"
                                                areaDescribedby="customerMobileNumber"
                                                placeholder="Customer's Mobile No."
                                                field="mobileNumber"
                                                required={true}
                                                defaultValue={record.customer.mobileNumber}
                                                disabled={true}
                                            />

                                        </div>
                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="joiningDate"
                                                classes="font-weight-bold text-dark"
                                                labelContent="Subscription Start Date *"
                                            />

                                            <DateInputComponent
                                                id="joiningDate"
                                                classes="form-control"
                                                areaDescribedby="customerJoiningDate"
                                                defaultValue={record.startDate}
                                                disabled={true}
                                            />

                                        </div>

                                    </div>

                                    <div className="form-row mt-3">
                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="endDate"
                                                classes="font-weight-bold text-dark"
                                                labelContent="Subscription End Date *"
                                            />

                                            <DateInputComponent
                                                id="endDate"
                                                classes="form-control"
                                                defaultValue={record.endDate}
                                                disabled={true}
                                            />

                                        </div>
                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="packageName"
                                                classes="font-weight-bold"
                                                labelContent="Package Given"
                                            />

                                            <TextInputComponent
                                                autoComplete="nope"
                                                classes="form-control"
                                                id="packageName"
                                                defaultValue={record.package.name + " | " + record.package.price}
                                                disabled={true}
                                            />

                                        </div>
                                    </div>

                                    <div className="form-row mt-3">
                                        <div className="form-group col-md-6">
                                            <LableComponent
                                                htmlFor="discount"
                                                classes="font-weight-bold"
                                                labelContent="Alloat Discount Package"
                                            />

                                            <SelectInputComponent
                                                autoComplete="nope"
                                                classes="form-control"
                                                id="discount"
                                                areaDescribedby="discountId"
                                                pipeToParent={this.setPaymentDetails}
                                                field="discount"
                                                isObject={true}
                                                defaultOption="--Select Discount--"
                                                array={this.state.discounts}
                                            />

                                            <SmallComponent
                                                id="discountId"
                                                classes="form-text font-weight-bold text-danger"
                                                content="Assign A Discount If It's Applicable !"
                                            />

                                        </div>
                                    </div>
                                    <div className="py-4">
                                        <div className="col-12 text-center">
                                            <button type="submit" className="btn btn-primary font-weight-bold text-white w-75" >Save Payment</button>
                                        </div>
                                    </div>

                                </form>
                                :
                                <TimePass />
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.showLoading && (
                        <ApplicationBlocker
                            title={this.state.loadingTitle}
                            showCloseButton={this.state.showCloseButton}
                            unsetBusyState={this.unsetBusyState}
                            description={this.state.loadingDescription}
                            extraInfo={this.state.extraInfo}
                            showLoadingSpinner={this.state.showLoadingSpinner}
                        />
                    )
                }
            </React.Fragment >
        );
    }
}
