import React, { Component } from 'react'
import TableComponent from '../../reusables/TableComponent';

export default class CustomersListComponent extends Component {

    navigateToForm = () => {
        return this.props.history.push({
            pathname: "/home/add-customer-form",
            auth: true,
            user: this.props.user
        });
    }

    handleViewDetails = (row) => {
        return this.props.history.push({
            pathname: "/home/view-details",
            auth: true,
            user: this.props.user,
            record: row
        });
    }

    handleEditDetails = (row) => {
        return this.props.history.push({
            pathname: "/home/edit-details",
            auth: true,
            user: this.props.user,
            record: row
        });
    }

    handlePaymentHistory = (row)=>{
        try{
            alert("Under Construction ...");
        }catch(error){
            
        }
    }

    navigateToAddSubscription = (row) => {
        return this.props.history.push({
            pathname: "/home/add-subscription",
            auth: true,
            user: this.props.user,
            record: row
        });
    }

    render() {
        return (
            <div>
                <div className="jumbotron jumbotron-fluid mt-0 mb-0 pb-0 pt-1 bg-white">
                    <div className="container-fluid">
                        <div className="d-grid gap-2 d-flex justify-content-end">
                            <button className="btn btn-primary btn-sm me-md-2" type="button"
                                onClick={this.navigateToForm}>
                                <i className="fa fa-plus" aria-hidden="true"></i> Add Customer
                            </button>
                        </div>
                        <p className="text-muted">Available Customers</p>
                        <div className="mt-1 p-0" style={{ height: "500px", overflow: "scroll" }}>
                            <TableComponent
                                columns={["Image", "Name", "Date", "Mobile"]}
                                fields={["image", "name", "date", "mobileNumber"]}
                                details={{ uri: "/fetch-customers", searchUri: '/search-customers' }}
                                showActions={true}
                                actions={[
                                    {
                                        label: "View Details",
                                        onClickFunction: this.handleViewDetails
                                    },
                                    {
                                        label: "Edit Details",
                                        onClickFunction: this.handleEditDetails
                                    },
                                    {
                                        label: "Add Subscription",
                                        onClickFunction: this.navigateToAddSubscription
                                    },
                                    {
                                        label: "Payment History",
                                        onClickFunction: this.handlePaymentHistory
                                    }
                                ]}
                                {...this.props}
                            />
                        </div>
                        {/* <FooterComponent /> */}
                    </div>
                </div>
            </div>
        )
    }
}
