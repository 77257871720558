import React, { Component } from 'react';

class TableLoaderComponent extends Component {
    render() {
        return (
            <div>
                <div className="d-flex align-items-center justify-content-center" style={{ height: "500px" }}>
                    <div className="spinner-border text-primary" style={{ width: "6rem", height: "6rem" }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default TableLoaderComponent;