import React, { Component } from 'react';
import { toEqualSpaces, toTitleCase } from '../../utils/InputHelper';

class TextareaInputComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: ""
        }
    }

    handleOnChange = (e) => {
        let value = e.target.value;
        let { allowSpaces, regex } = this.props;

        if (regex) {
            if (!regex.exec(value))
                return this.setState({ value: value });
        }

        if (!allowSpaces) {
            const expression = /[^A-Za-z0-9.@-]/;
            if (!expression.exec(value))
                return this.setState({ value: value });
            value = this.state.value;
        }

        return this.setState({ value: value });
    }

    handleOnBlur = (e) => {
        let value = e.target.value;
        let { allowSpaces, titleCase, pipeToParent, field } = this.props;

        // insert one space after each word except first and last word when foucus goes out 
        if (allowSpaces) {
            value = toEqualSpaces(value);
        }

        // changes to title case when foucus goes out 
        if (titleCase) {
            value = toTitleCase(value);
        }

        this.setState({ value: value });
        if (pipeToParent)
            return this.props.pipeToParent(field, value);
    }

    componentDidMount() {
        let { defaultValue } = this.props;
        if (defaultValue)
            return this.setState({ value: defaultValue });
    }

    render() {
        let { id, classes, rows, autoComplete, placeholder, required = false, disabled = false } = this.props;
        return (
            <textarea
                className={classes}
                id={id}
                rows={rows}
                autoComplete={autoComplete}
                required={required}
                placeholder={placeholder}
                value={this.state.value}
                onChange={this.handleOnChange}
                onBlur={this.handleOnBlur}
                disabled={disabled}
            >
            </textarea>
        );
    }
}

export default TextareaInputComponent;