import React, { Component } from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import DiscountsComponent from './discounts/DiscountsComponent';

import PacksComponent from './packages/PacksComponent'

class ConfigurationsComponent extends Component {
    render() {
        return (
            <div className="container-fluid">
                <ul className="nav nav-pills nav-fill">
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/home/configurations/packs">Packs</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/home/configurations/discounts">Discounts</NavLink>
                    </li>
                </ul>
                <div className="pt-1">
                    <Switch>
                        <Route
                            exact
                            path="/home/configurations/packs"
                            component={() => (
                                <PacksComponent {...this.props} />
                            )}
                        />
                        <Route
                            exact
                            path="/home/configurations/discounts"
                            component={() => (
                                <DiscountsComponent {...this.props} />
                            )}
                        />
                        <Redirect from="/*" to="/home/configurations/packs" />
                    </Switch>

                </div>
            </div>
        );
    }
}

export default ConfigurationsComponent;